import { request } from 'shared/lib/request';

const isMySchool = process.env.REACT_APP_PROJECT_TYPE === 'MY_SCHOOL';

export const search = (Query = '', signal) =>
  request(isMySchool ? 'v1/User/GetBy' : 'v1/User/search', {
    method: 'POST',
    body: { Query },
    signal
  });

export const getUserInfo = (Ids) =>
  request('v1/User/get', { method: 'POST', body: { Ids } });

export const getAuthInfo = () => request('v1/user');

export const addRole = ({ roleId, userId }) =>
  request(`v1/User/AddRole?roleId=${roleId}&userId=${userId}`, {}, 'text');

export const removeRole = ({ roleId, userId }) =>
  request(
    `v1/User/RemoveRole?roleId=${roleId}&userId=${userId}`,
    {
      method: 'DELETE'
    },
    'text'
  );

export const changeStatus = ({ userId, status }) =>
  request(`v1/user/ChangeStatus?userId=${userId}&status=${status}`);
