import { request } from 'shared/lib/request';

export const login = (body) =>
  request('v2/auth/Login', { method: 'POST', body });

export const getSettings = () => request('v1/settings');

export const addUser = (body) =>
  request('v2/auth/AddUser', { method: 'POST', body });

export const register = (body) =>
  request('v2/auth/register', { method: 'POST', body });
