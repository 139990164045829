import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { LoadingBox } from '../../shared/components';

const defaultActions = {
  cancel: {
    enabled: true,
    variant: 'text',
    color: 'inherit',
    text: 'Отмена'
  },
  confirm: {
    enabled: true,
    color: 'primary',
    variant: 'contained',
    text: 'Ок'
  }
};

export const ConfirmDialog = ({
  loading,
  title,
  content,
  actions: actionsProps,
  onCancel,
  onConfirm
}) => {
  const actions = {
    cancel: { ...defaultActions.cancel, ...(actionsProps?.cancel ?? {}) },
    confirm: { ...defaultActions.confirm, ...(actionsProps?.confirm ?? {}) }
  };

  return (
    <Dialog open={true} onClose={onCancel}>
      <LoadingBox loading={loading}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
          {actions.cancel.enabled && (
            <Button
              variant={actions.cancel.variant}
              color={actions.cancel.color}
              onClick={onCancel}
            >
              {actions.cancel.text}
            </Button>
          )}
          {actions.confirm.enabled && (
            <Button
              variant={actions.confirm.variant}
              color={actions.confirm.color}
              onClick={onConfirm}
            >
              {actions.confirm.text}
            </Button>
          )}
        </DialogActions>
      </LoadingBox>
    </Dialog>
  );
};
