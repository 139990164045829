import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Autocomplete, TextField, Stack } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { formErrorMessages } from 'shared/constants';
import * as profileApi from 'entities/profile/api';
import { selectRoleList } from 'entities/role';
import {
  addUserRoleThunk,
  removeUserRoleThunk,
  searchUsersThunk
} from 'entities/user';
import { BaseDialog } from '../BaseDialog';
import { showSnackbar } from 'features/Snackbar';

export const UpdateUserDialog = ({ user, onClose }) => {
  const dispatch = useDispatch();

  const roles = useSelector(selectRoleList);

  const [loading, setLoading] = React.useState(false);

  const defaultValues = React.useMemo(
    () => ({
      Id: user.Id,
      Login: user.Email || user.PhoneNumber,
      OldPassword: '',
      NewPassword: '',
      FirstName: user.FirstName || '',
      Surname: user.Surname || '',
      Company: user.Company || '',
      Position: user.Position || '',
      Roles: user.Roles?.map((role) => role.Id) ?? []
    }),
    [user]
  );

  const form = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    setLoading(true);

    let res;
    try {
      res = await profileApi.update(data);
    } catch (error) {
      dispatch(
        showSnackbar({
          type: 'error',
          message: error.ErrorMessage
        })
      );
      setLoading(false);
    }

    if (!res) return false;

    const addRoleIds = form
      .getValues()
      .Roles.filter((id) => !user.Roles.find((role) => role.Id === id));

    const removeRoleIds = user.Roles.filter(
      (role) => !form.getValues().Roles.includes(role.Id)
    )
      .map((role) => role.Id)
      .filter((id) => !addRoleIds.includes(id));

    await Promise.all([
      ...addRoleIds.map((roleId) =>
        dispatch(addUserRoleThunk({ roleId, userId: user.Id })).unwrap()
      ),
      ...removeRoleIds.map((roleId) =>
        dispatch(removeUserRoleThunk({ roleId, userId: user.Id })).unwrap()
      )
    ]);

    await dispatch(searchUsersThunk()).unwrap();
    dispatch(
      showSnackbar({
        type: 'success',
        message: `Пользователь #${data.Id} успешно обновлен`
      })
    );
    setLoading(false);
    onClose();
  };

  const getTextFieldErrorProps = (name) => {
    const message = form.formState.errors[name]?.message;

    if (!message) return {};

    return {
      error: true,
      helperText: message
    };
  };

  React.useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  return (
    <BaseDialog
      loading={loading}
      title="Редактирование пользователя"
      actions={
        <>
          <Button color="error" onClick={onClose}>
            Отмена
          </Button>
          <Button onClick={form.handleSubmit(onSubmit)}>Сохранить</Button>
        </>
      }
      onClose={onClose}
    >
      <Stack spacing={2}>
        <Controller
          control={form.control}
          name="Login"
          render={({ field }) => (
            <TextField
              size="small"
              label="Логин"
              disabled={true}
              {...field}
              {...getTextFieldErrorProps('Login')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="OldPassword"
          render={({ field }) => (
            <TextField
              size="small"
              label="Старый пароль"
              {...field}
              {...getTextFieldErrorProps('OldPassword')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="NewPassword"
          render={({ field }) => (
            <TextField
              size="small"
              label="Новый пароль"
              {...field}
              {...getTextFieldErrorProps('NewPassword')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="FirstName"
          render={({ field }) => (
            <TextField
              label="Имя"
              {...field}
              {...getTextFieldErrorProps('FirstName')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="Surname"
          render={({ field }) => (
            <TextField
              size="small"
              label="Фамилия"
              {...field}
              {...getTextFieldErrorProps('Surname')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="Company"
          render={({ field }) => (
            <TextField
              size="small"
              label="Компания"
              {...field}
              {...getTextFieldErrorProps('Company')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="Position"
          render={({ field }) => (
            <TextField
              size="small"
              label="Должность"
              {...field}
              {...getTextFieldErrorProps('Position')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="Roles"
          render={({ field }) => (
            <Autocomplete
              multiple
              disableCloseOnSelect
              size="small"
              value={roles.filter((role) => field.value.includes(role.Id))}
              options={roles}
              getOptionLabel={(option) => `#${option.Id} ${option.Name}`}
              onChange={(_, value) =>
                field.onChange(value.map((item) => item.Id))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getTextFieldErrorProps('Roles')}
                  label="Роль"
                />
              )}
            />
          )}
        />
      </Stack>
    </BaseDialog>
  );
};

const schema = yup.object().shape({
  // Login: yup
  //   .string()
  //   //.email(formErrorMessages.email)
  //   .required(formErrorMessages.required),
  OldPassword: yup.string(),
  NewPassword: yup.string(),
  FirstName: yup.string().required(formErrorMessages.required),
  Surname: yup.string().required(formErrorMessages.required),
  Company: yup.string(),
  Position: yup.string(),
  Roles: yup.array().min(1, formErrorMessages.arrayMin(1))
});
