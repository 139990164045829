import { request } from 'shared/lib/request';

export const search = (params) => request(`v1/Documents/Search?${params}`);

export const share = (body) =>
  request('v1/Documents/Share', { method: 'POST', body });

export const unshare = (body) =>
  request('v1/Documents/UnShare', { method: 'POST', body });

export const rename = (body) =>
  request(`v1/Documents/Rename?${new URLSearchParams(body).toString()}`);

export const copy = (body) =>
  request(`v1/Documents/Copy?${new URLSearchParams(body).toString()}`);

export const move = (body) =>
  request('v1/Documents/Move', { method: 'POST', body });

export const download = (params) =>
  request(
    `v1/Documents/Download?${new URLSearchParams(
      Object.fromEntries(
        Object.entries(params).filter((values) => {
          return ![null, undefined].includes(values[1]);
        })
      )
    ).toString()}`,
    { method: 'GET' },
    'raw'
  );

export const deleteItem = (Ids) =>
  request('v1/Documents/Delete', { method: 'POST', body: { Ids } });

export const deleteFile = (id) =>
  request(`v1/Files?Id=${id}`, { method: 'DELETE' });

export const getFileRights = (fileId) =>
  request(`v1/Files/GetRights?Id=${fileId}`);

export const restoreItem = (body) =>
  request('v1/Documents/Restore', { method: 'POST', body });

export const versions = (id) => request(`v1/Documents/Versions?id=${id}`);

export const changeVersion = (id, fileId) =>
  request(`v1/Documents/ChangeVersion?Id=${id}&FileId=${fileId}`);

export const changeType = ({ id, type }) =>
  request(`v1/Documents/ChangeType?id=${id}&type=${type}`);

export const convert = ({ id, type }) =>
  request(`v1/Documents/Convert?id=${id}&type=${type}`);

export const getDocumentInfo = ({ id }) => request(`v1/Documents?id=${id}`);
