import * as React from 'react';
import {
  useTheme,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Box
} from '@mui/material';
import { Apps } from '@mui/icons-material';
import { selectSettings } from 'entities/auth';
import { useSelector } from 'react-redux';

export const AppsMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = React.useState();
  const handleClose = () => setAnchorEl(null);
  const settings = useSelector(selectSettings);
  const modules = settings.ModuleSettings;
  const menu = modules ? modules.ModuleUrls.filter(x => x.ShowItem) : [];

  return (
    menu.length > 0 && (
      <>
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <Apps />
        </IconButton>
        <Menu
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={handleClose}
        >
          {menu.map((item) => (
            <MenuItem
              key={item.Url}
              component="a"
              href={item.Url}
              target='_blank'
              onClick={handleClose}
              sx={{ minHeight: isMobile ? 40 : '' }}
            >
              <ListItemIcon>
                <Box component="img" src={item.Icon} height={24} />
              </ListItemIcon>
              {item.Title}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  );
};
