import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as api from './api';

export const fetchSearchResults = createAsyncThunk(
  'search/fetchResults',
  api.getResults
);

const initialState = {
  results: [],
  resultsStatus: 'idle'
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.resultsStatus = 'loading';
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.resultsStatus = 'success';
        state.results = action.payload;
      })
      .addCase(fetchSearchResults.rejected, (state) => {
        state.resultsStatus = 'error';
      })
});

export const searchReducer = searchSlice.reducer;

export const selectSearchResults = (state) => state.search.results;
export const selectSearchResultsStatus = (state) => state.search.resultsStatus;
