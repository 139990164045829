import * as React from 'react';
import { styled, Stack } from '@mui/material';
import { DriveFolderUpload } from '@mui/icons-material';

import { BaseDialog } from 'dialogs/BaseDialog';

export const UploadDialog = ({
  title = 'Загрузить',
  loading,
  onChange,
  onClose
}) => {
  const [dragEnter, setDragEnter] = React.useState(false);

  const containerRef = React.useRef();
  const inputFileRef = React.useRef();

  const handleDragOver = (event) => event.preventDefault();

  const handleDrop = (event) => {
    event.preventDefault();
    onChange(event.dataTransfer.files);
  };

  const handleDragEnter = (event) => {
    if (!dragEnter && containerRef.current.contains(event.target)) {
      setDragEnter(true);
    }
  };

  const handleDragLeave = (event) => {
    if (dragEnter && !containerRef.current.contains(event.target)) {
      setDragEnter(false);
    }
  };

  return (
    <BaseDialog loading={loading} title={title} onClose={onClose}>
      <div ref={containerRef}>
        <DropZone
          className={dragEnter ? 'enter' : ''}
          onClick={() => inputFileRef.current.click()}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <DriveFolderUpload sx={{ color: 'text.disabled', fontSize: 90 }} />
          Перетащите файлы сюда или нажмите, чтобы выбрать файлы
        </DropZone>
      </div>
      <input
        multiple
        type="file"
        ref={inputFileRef}
        onChangeCapture={(event) => onChange(event.target.files)}
        style={{ display: 'none' }}
      />
    </BaseDialog>
  );
};

const DropZone = styled((props) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={(theme) => ({
      'cursor': 'pointer',
      'border': `2px dashed ${theme.palette.divider}`,
      'borderRadius': 1,
      'height': 240,
      'p': 3,
      '&:hover': {
        backgroundColor: theme.palette.grey[50]
      },
      '&.enter': {
        borderColor: theme.palette.primary.main
      }
    })}
    {...props}
  />
))({});
