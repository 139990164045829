import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  IconButton
} from '@mui/material';
import { Restore } from '@mui/icons-material';
import { Download } from '@mui/icons-material';
import { showSnackbar } from 'features/Snackbar';

import { selectViewer } from 'entities/auth';
import { getUserInfo } from 'entities/user';
import { fetchDirectoryThunk } from 'entities/documentDirectory';
import { versions, changeVersion } from 'entities/documents/api';
import { getDocumentInfoThunk } from 'entities/documents';
import { BaseDialog } from '../BaseDialog';

export const DocumentVersions = ({ entity, onClose }) => {
  const dispatch = useDispatch();

  const viewer = useSelector(selectViewer);

  const [rows, setRows] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [curentVersion, setCurrentVersion] = React.useState(entity.FileId);
  const [pending, setPending] = React.useState(true);

  const getData = async () => {
    setLoading(true);
    try {
      const versionsData = await versions(entity.Id);
      //#not rly need to get users again if we changed version => change this later
      const usersData = await getUserInfo(
        versionsData.map((item) => item.OwnerId)
      );

      setRows(versionsData);
      setUsers(
        Object.fromEntries([
          ...new Set(
            usersData.map((user) => [
              user.Id,
              `${user.FirstName} ${user.Surname}`
            ])
          )
        ])
      );
    } finally {
      setLoading(false);
    }
  };

  const restore = async (id, fileId) => {
    await changeVersion(id, fileId);
    setCurrentVersion(fileId);
    getData();
    dispatch(fetchDirectoryThunk());
  };

  const download = (id, fileId) => {
    const opener = window.open(
      `/api/v1/Documents/Download?id=${id}&FileId=${fileId}&download=true`,
      '_blank'
    );
    opener.window.onload = () => {
      if (opener.window.document.contentType === 'application/json') {
        const body = JSON.parse(opener.window.document.body.innerText);
        if (body.ErrorCode === 410) {
          dispatch(
            showSnackbar({
              type: 'error',
              message: `Документ версии ${fileId} отсутствует на диске`
            })
          );
        }
        opener.window.close();
      }
    };
    //window.location.href = `/api/v1/Documents/Download?id=${id}&FileId=${fileId}&download=true`;
  };

  React.useEffect(() => {
    let timeoutId;

    const checkPending = () =>
      entity?.closedTimestamp && Date.now() - entity.closedTimestamp < 12_000;

    const watch = () => {
      timeoutId = setTimeout(async () => {
        if (!checkPending()) {
          const updatedEntity = await dispatch(
            getDocumentInfoThunk({
              id: entity.Id
            })
          ).unwrap();
          setCurrentVersion(updatedEntity.FileId);
          setPending(false);
          clearTimeout(timeoutId);
          getData();
        } else {
          watch();
        }
      }, 500);
    };

    watch();

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.Id]);

  return (
    <BaseDialog
      title="История версий"
      loading={loading || pending}
      onClose={onClose}
      actions={<Button onClick={onClose}>Закрыть</Button>}
    >
      {!rows?.length ? (
        <Typography>Нет данных</Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Номер</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Пользователь</TableCell>
                <TableCell>Версия</TableCell>
                <TableCell>Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={
                    index !== rows.length - 1
                      ? null
                      : { '& td': { borderBottom: 'none' } }
                  }
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {new Date(row.Date).toLocaleString('ru')}
                  </TableCell>
                  <TableCell>
                    {row.OwnerId === viewer.Id ? 'Я' : users[row.OwnerId]}
                  </TableCell>
                  <TableCell>{row.FileId}</TableCell>
                  <TableCell>
                    {!row.IsCurrent ? (
                      <>
                        <IconButton onClick={() => restore(row.Id, row.FileId)}>
                          <Restore />
                        </IconButton>
                        <IconButton
                          onClick={() => download(row.Id, row.FileId)}
                        >
                          <Download />
                        </IconButton>
                      </>
                    ) : (
                      <span>Текущая версия</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </BaseDialog>
  );
};
