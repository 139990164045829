import * as React from 'react';
import { Box, Stack, CircularProgress } from '@mui/material';

export const LoadingBox = ({ loading, spinnerSize = 40, children }) => (
  <Box sx={{ pointerEvents: loading ? 'none' : '', position: 'relative' }}>
    <Box sx={{ position: 'relative', zIndex: 1 }}>{children}</Box>
    {loading && (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.84)',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 2
        }}
      >
        <CircularProgress size={spinnerSize} />
      </Stack>
    )}
  </Box>
);
