import * as React from 'react';
import { NavLink } from 'react-router-dom';
import {
  useTheme,
  useMediaQuery,
  styled,
  Box,
  Button,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  IconButton,
  Divider
} from '@mui/material';

import { ChevronRight, Close } from '@mui/icons-material';
import logoSrc from 'shared/images/logo2.svg';
import logoSrc2 from 'shared/images/logo-school.png';
import r7InfoBgSrc from 'shared/images/r7info-bg.png';
import { ReactComponent as R7Icon } from 'shared/svg/logo-r7-default.svg';
import { Rooms } from 'features/rooms';
import { DiskSpaceInfo } from 'features/DiskSpaceInfo';
import { UserMenu } from 'features/UserMenu';
import { widgets } from 'shared/constants';
import { isR7, isMySchool } from 'shared/constants';

export const Navigation = ({ menu, header }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showInfoWnd, setShowInfoWnd] = React.useState(false);

  const openInfoWnd = () => {
    setShowInfoWnd(true);
  };

  return (
    <Root>
      <Header>
        {isMySchool ? (
          <a href="https://myschool.edu.ru">
            <img src={logoSrc2} alt="Моя Школа" height={50} />
          </a>
        ) : (
          <a href="/">
            <img src={logoSrc} alt="Р7-Офис" height={38} />
          </a>
        )}
        {isMobile && (
          <Box sx={{ ml: 'auto' }}>
            <UserMenu />
          </Box>
        )}
      </Header>
      <Box
        sx={(theme) => ({
          height: widgets.pageHeaderHeight,
          borderRight: `1px solid ${theme.palette.divider}`,
          flexShrink: 0
        })}
      >
        {header}
      </Box>
      <Stack
        as="nav"
        sx={(theme) => ({
          borderRight: `1px solid ${theme.palette.divider}`,
          flexGrow: 1,
          height: 'calc(100% - 160px)'
        })}
      >
        <Box
          sx={{
            'overflowY': 'auto',
            '&::-webkit-scrollbar': { width: 3 },
            '&::-webkit-scrollbar-thumb': {
              backgroundImage:
                'linear-gradient(180deg, #d036da 0%, #708ad4 99%)',
              boxShadow: 'inset 2px 2px 5px 0 rgba(#fff, 0.5)',
              borderRadius: 20
            }
          }}
        >
          <List sx={{ p: 0 }}>
            {menu.map((item) => (
              <ListItem key={item.path} sx={{ p: 0 }}>
                <StyledNavLink to={item.path}>
                  {({ isActive }) => (
                    <NavLinkContent
                      sx={!isActive ? null : { bgcolor: 'grey.100' }}
                    >
                      <ListItemIcon sx={{ color: 'grey.600', minWidth: 40 }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ m: 0 }}
                        primaryTypographyProps={{
                          sx: { color: 'grey.800', fontWeight: 'medium' }
                        }}
                      >
                        {item.title}
                      </ListItemText>
                      {isActive && <ChevronRight sx={{ color: 'grey.400' }} />}
                    </NavLinkContent>
                  )}
                </StyledNavLink>
              </ListItem>
            ))}
          </List>
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <Box
              sx={{
                left: 0,
                top: 0,
                height: '100%',
                width: '100%'
              }}
            >
              <Rooms />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 3 }}>
          <DiskSpaceInfo />
        </Box>
        {isR7 && (
          <>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                onClick={openInfoWnd}
                sx={{
                  height: 25,
                  color: '#868E96',
                  bgcolor: '#F5F6F8',
                  borderRadius: 10,
                  fontSize: 12
                }}
              >
                &nbsp;Работает на Р7-Офис&nbsp;
              </Button>
            </Box>
            {/*Info Dialog*/}
            <Dialog
              fullWidth
              open={showInfoWnd}
              PaperProps={{
                style: {
                  backgroundImage: `url('${r7InfoBgSrc}')`,
                  backgroundPosition: 'right 0px top',
                  backgroundRepeat: 'no-repeat',
                  width: 640,
                  maxWidth: 640
                }
              }}
            >
              <DialogContent
                sx={{
                  padding: '30px 10px 10px 30px',
                  width: 640,
                  height: 380,
                  overflow: 'hidden'
                }}
              >
                <IconButton
                  sx={{ position: 'absolute', right: 20, top: 20 }}
                  onClick={() => setShowInfoWnd(false)}
                >
                  <Close />
                </IconButton>
                <R7Icon style={{ fill: 'grey', flexShrink: 0 }} />
                <Box sx={{ width: 380, fontSize: 14 }}>
                  <p>
                    Р7-Офис — российский кроссплатформенный пакет приложений для
                    совместной работы с офисными документами.
                  </p>
                  <p>
                    Входит в единый реестр российских программ для ЭВМ и баз
                    данных.
                  </p>
                  <p>
                    Доступен в облачной, локальной и мобильной версиях,
                    поддерживает развертывание в частном облаке и ряд решений
                    для совместной работы.
                  </p>
                </Box>
                <Button
                  variant="contained"
                  onClick={() => window.open('https://r7-office.ru')}
                >
                  Узнать больше на сайте
                </Button>
                <p style={{ color: '#868E96', fontSize: 12 }}>
                  © АО «Р7». Все права защищены
                </p>
              </DialogContent>
            </Dialog>
          </>
        )}
      </Stack>
    </Root>
  );
};

const Root = styled((props) => (
  <Box
    as="aside"
    sx={{
      height: '100dvh',
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'column',
      width: widgets.sidebarWidth,
      position: 'sticky',
      top: 0,
      paddingBottom: 1.5
    }}
    {...props}
  />
))({});

const Header = styled((props) => (
  <Stack direction="row" alignItems="center" {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: `${widgets.headerHeight}px`,
  padding: theme.spacing(0, 3),
  flexShrink: 0
}));

const StyledNavLink = styled((props) => (
  <NavLink draggable={false} {...props} />
))(({ theme }) => ({
  flexGrow: 1,
  color: 'inherit',
  textDecoration: 'none',
  padding: theme.spacing(0, 1)
}));

const NavLinkContent = styled('span')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.25, 2),
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}));
