import * as React from 'react';
import { useDispatch } from 'react-redux';
import { styled, Button } from '@mui/material';

import { userTaskStatuses, checkUserTask } from 'entities/userTask';
import {
  fetchDirectoryThunk,
  conflictDirectoryThunk,
  moveDirectoryThunk
} from 'entities/documentDirectory';
import { moveDocumentThunk } from 'entities/documents';
import { showSnackbar } from 'features/Snackbar';
import { DirectoryExplorer } from 'features/directoryExplorer';
import { ConflictDialog } from '../Conflict';
import { BaseDialog } from '../BaseDialog';

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const MoveDialog = ({ entity, currentDirectoryId, onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [newFolderVisible, setNewFolderVisible] = React.useState(false);
  const [data, setData] = React.useState({});
  const [conflict, setConflict] = React.useState(false);

  const handleCloseConflictDialog = () => setConflict(false);

  const isSelfNested = () => {
    let target = data?.selected ?? data?.current;

    if (!target?.Parent) return false;

    if (target?.Id === entity.Id) return true;

    while (target.Parent) {
      target = target.Parent;

      if (target.Id === entity.Id) {
        return true;
      }
    }

    return false;
  };

  const handleSubmit = async () => {
    if (entity?.Parent?.Id === data?.selected?.Id ?? data?.current?.Id) {
      dispatch(
        showSnackbar({
          type: 'warning',
          message:
            'Нельзя переместить в текущую папку. Папка уже находится здесь'
        })
      );

      return;
    }

    if (isSelfNested()) {
      dispatch(
        showSnackbar({
          type: 'warning',
          message: 'Нельзя переместить папку в свою дочернюю папку'
        })
      );

      return;
    }

    setLoading(true);

    try {
      if (entity.IsFolder) {
        const { Conflict } = await dispatch(
          conflictDirectoryThunk({
            toDirectoryId: data?.selected?.Id ?? data?.current?.Id,
            ids: [entity.Id]
          })
        ).unwrap();

        setConflict(Conflict);

        if (Conflict) return;

        const { TaskId } = await dispatch(
          moveDirectoryThunk({
            id: entity.Id,
            toDirectoryId: data?.selected?.Id ?? data?.current?.Id
          })
        ).unwrap();

        const loop = async () => {
          const response = await checkUserTask(TaskId);

          if (response.Status === userTaskStatuses.error) {
            dispatch(
              showSnackbar({
                type: 'error',
                message: 'Ошибка перемещения'
              })
            );
            return;
          }

          if (response.Status === userTaskStatuses.done) {
            return;
          }

          await wait(1000);
          await loop();
        };

        await loop();
      } else {
        await dispatch(
          moveDocumentThunk({
            ToDirectoryId: data?.selected?.Id ?? data?.current?.Id,
            Ids: [entity.Id]
          })
        ).unwrap();
      }

      onClose();
      dispatch(fetchDirectoryThunk());
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseDialog
      loading={loading}
      title="Переместить"
      onClose={onClose}
      actions={
        <>
          <StyledButton
            variant="outlined"
            sx={{ width: 'calc(50% - 20px)' }}
            onClick={onClose}
          >
            Отмена
          </StyledButton>
          {/* запрещаем перенос в корень комнат */}
          {data?.current?.Type !== 4096 && (
            <Button
              disabled={newFolderVisible}
              variant="contained"
              onClick={handleSubmit}
              sx={{
                'width': 'calc(50% - 20px)',
                '&:not(:disabled)': {
                  bgcolor: '#ff6a6a !important'
                }
              }}
            >
              Переместить
            </Button>
          )}
        </>
      }
    >
      <DirectoryExplorer
        initialDirectoryId={currentDirectoryId}
        newFolderVisible={newFolderVisible}
        setNewFolderVisible={setNewFolderVisible}
        data={data}
        onChange={setData}
      />
      <ConflictDialog
        isMove
        entityId={entity.Id}
        directoryId={data?.selected?.Id ?? data?.current?.Id}
        open={conflict}
        onCopyStart={() => {
          setLoading(true);
          handleCloseConflictDialog();
        }}
        onCopyEnd={() => {
          setLoading(false);
          onClose();
        }}
        onClose={handleCloseConflictDialog}
      />
    </BaseDialog>
  );
};

const StyledButton = styled((props) => (
  <Button
    variant="outlined"
    color="inherit"
    sx={{ color: 'text.primary', borderColor: 'divider' }}
    {...props}
  />
))({});
