import * as React from 'react';
import {
  useTheme,
  useMediaQuery,
  Paper,
  Stack,
  Typography,
  CardMedia,
  IconButton
} from '@mui/material';
import {
  OndemandVideo,
  Image,
  Minimize,
  Close,
  ErrorOutline
} from '@mui/icons-material';

import { Player } from 'shared/components';
import { mediaCardWidth } from './config';

export const MediaCard = React.memo(
  ({ card, onFocus, onMinimize, onRemove, onDragEnd }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [isError, setIsError] = React.useState(false);

    const rootRef = React.useRef();
    const playerRef = React.useRef();

    const TypeIconComponent = card.video ? OndemandVideo : Image;

    const handleError = () => setIsError(true);

    const handleMinimize = () => {
      if ((card.video || card.audio) && card.visible) {
        playerRef.current.plyr.pause();
      }
      onMinimize(card);
    };

    const handleDraggable = (card) => (event) => {
      event.preventDefault();

      const minX = -(rootRef.current.offsetWidth / 100) * 75;
      const maxX = window.innerWidth - (rootRef.current.offsetWidth / 100) * 25;
      const minY = 0;
      const maxY = window.innerHeight - 40;
      let lastX = event.clientX;
      let lastY = event.clientY;

      const onMove = (event) => {
        let x = parseFloat(rootRef.current.style.left) + event.clientX - lastX;
        let y = parseFloat(rootRef.current.style.top) + event.clientY - lastY;

        lastX = event.clientX;
        lastY = event.clientY;

        y < minY && (y = minY);
        y > maxY && (y = maxY);
        x < minX && (x = minX);
        x > maxX && (x = maxX);

        rootRef.current.style.left = `${x}px`;
        rootRef.current.style.top = `${y}px`;
      };

      const onEnd = () => {
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onEnd);

        onDragEnd({
          uid: card.uid,
          x: parseFloat(rootRef.current.style.left),
          y: parseFloat(rootRef.current.style.top)
        });
      };

      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onEnd);
    };

    return (
      <Paper
        ref={rootRef}
        elevation={20}
        onMouseDown={() => onFocus(card)}
        onErrorCapture={(event) => {
          event.stopPropagation();
          handleError();
        }}
        sx={{
          width: isMobile ? 'calc(100% - 16px)' : mediaCardWidth,
          height: isMobile ? 'calc(100% - 16px)' : 'auto',
          position: 'fixed'
        }}
        style={{
          left: isMobile ? 8 : `${card.x}px`,
          top: isMobile ? 8 : `${card.y}px`,
          zIndex: card.zIndex,
          display: card.visible ? '' : 'none'
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          onMouseDown={handleDraggable(card)}
          sx={(theme) => ({
            height: 40,
            px: 1,
            cursor: 'move',
            boxShadow: theme.shadows[4],
            position: 'relative',
            zIndex: 1
          })}
        >
          <TypeIconComponent sx={{ color: 'text.secondary', mr: 1 }} />
          <Typography noWrap>{card.title}</Typography>
          {!isMobile && (
            <IconButton
              color="inherit"
              onMouseDown={(event) => event.stopPropagation()}
              onClick={handleMinimize}
              sx={{ ml: 'auto', color: 'text.secondary' }}
            >
              <Minimize sx={{ fontSize: 18 }} />
            </IconButton>
          )}
          <IconButton
            color="inherit"
            onMouseDown={(event) => event.stopPropagation()}
            onClick={() => onRemove(card)}
            sx={{ color: 'text.secondary' }}
          >
            <Close sx={{ fontSize: 18 }} />
          </IconButton>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            borderBottomLeftRadius: 1,
            borderBottomRightRadius: 1,
            overflow: 'hidden',
            height: 'calc(100% - 40px)'
          }}
        >
          {isError && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ py: 3 }}
            >
              <ErrorOutline sx={{ color: 'error.main' }} />
              <Typography sx={{ color: 'error.main' }}>
                Невозможно загрузить ресурс
              </Typography>
            </Stack>
          )}
          <div style={{ display: isError ? 'none' : '' }}>
            {!!card.video && <Player ref={playerRef} {...card.video} />}
            {!!card.audio && <Player ref={playerRef} {...card.audio} />}
          </div>
          {!isError && !!card.image && (
            <CardMedia
              component="img"
              image={card.image.src}
              sx={
                !isMobile
                  ? {}
                  : {
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }
              }
            />
          )}
        </Stack>
      </Paper>
    );
  }
);
