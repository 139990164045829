import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Autocomplete, TextField } from '@mui/material';

import { convertDocumentThunk } from 'entities/documents';
import { BaseDialog } from 'dialogs/BaseDialog';
import { supportTypes } from 'shared/constants';

export const DocumentConvert = ({ entity, onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState(null);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await dispatch(
        convertDocumentThunk({
          id: entity.Id,
          type
        })
      ).unwrap();
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <BaseDialog
      title="Формат документа"
      loading={loading}
      onClose={onClose}
      actions={
        <>
          <Button color="error" onClick={onClose}>
            Отмена
          </Button>
          <Button onClick={handleSubmit}>Сохранить</Button>
        </>
      }
    >
      <Autocomplete
        value={type}
        options={mimeTypeExtensions[entity.MimeType] ?? []}
        onChange={(_, value) => setType(value)}
        renderInput={(params) => <TextField {...params} label="Формат" />}
      />
    </BaseDialog>
  );
};

const mimeTypeExtensions = {
  [supportTypes.word.mimeType]: [
    supportTypes.pdf.fileType,
    supportTypes.rtf.fileType,
    supportTypes.text.fileType
  ],
  [supportTypes.word_old.mimeType]: [
    supportTypes.word.fileType,
    supportTypes.pdf.fileType,
    supportTypes.rtf.fileType
  ],
  [supportTypes.pdf.mimeType]: [
    supportTypes.word.fileType,
    supportTypes.rtf.fileType,
    supportTypes.text.fileType
  ],
  [supportTypes.excel.mimeType]: [
    supportTypes.pdf.fileType,
    supportTypes.csv.fileType
  ],
  [supportTypes.excel_old.mimeType]: [
    supportTypes.pdf.fileType,
    supportTypes.csv.fileType
  ],
  [supportTypes.csv.mimeType]: [
    supportTypes.excel.fileType,
    supportTypes.pdf.fileType
  ],
  [supportTypes.powerPoint.mimeType]: [
    supportTypes.pdf.fileType,
    supportTypes.imgPng.fileType,
    supportTypes.imgJpg.fileType
  ],
  [supportTypes.rtf.mimeType]: [
    supportTypes.word.fileType,
    supportTypes.pdf.fileType,
    supportTypes.text.fileType
  ],
  [supportTypes.text.mimeType]: [
    supportTypes.word.fileType,
    supportTypes.pdf.fileType,
    supportTypes.rtf.fileType
  ]
};
