import { store } from 'app/store';
import { showSnackbar } from 'features/Snackbar';
import { wait } from 'shared/lib/wait';
import { checkUserTask } from './api';

export const userTaskStatuses = {
  error: -1,
  unknown: 0,
  register: 1,
  start: 2,
  done: 3
};

export const userTaskWatch = async (promise, options) => {
  const { TaskId } = await promise;

  let maxTryCount = options?.maxTryCount ?? 50;

  const watch = async () => {
    const response = await checkUserTask(TaskId);

    maxTryCount--;

    if (response.Status === userTaskStatuses.error) {
      store.dispatch(
        showSnackbar({
          type: 'error',
          message: options?.errorMessage ?? 'Ошибка'
        })
      );
    } else if (response.Status === userTaskStatuses.done) {
      store.dispatch(
        showSnackbar({
          type: 'success',
          message: options?.successMessage ?? 'Успех'
        })
      );
    } else if (maxTryCount === 0) {
      store.dispatch(
        showSnackbar({
          type: 'error',
          message:
            options?.limitExceededMessage ??
            'Превышен лимит ожидания выполнения задачи'
        })
      );
    } else {
      await wait(1000);
      await watch();
    }
  };

  await watch();
};
