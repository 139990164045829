import * as React from 'react';
import { Checkbox } from '@mui/material';

import { BaseTable } from '../BaseTable';

export const SelectionTable = ({
  selection = [],
  rows = [],
  columns,
  getRowProps,
  onSelectionChange,
  ...props
}) => {
  const onRowClick = (row) => {
    onSelectionChange(
      !selection.includes(row)
        ? selection.concat(row)
        : selection.filter((item) => item !== row)
    );
  };

  const getNewRowProps = (row, index) => {
    const props = getRowProps?.(row, index) ?? {};

    return {
      ...props,
      selected: !!selection.find((item) => item === row),
      onClick: (event) => {
        onRowClick(row);
        props.onClick?.(event, row, index);
      }
    };
  };

  const getHeaderCellProps = (index) => ({
    padding: index === 0 ? 'checkbox' : 'normal'
  });

  const getCellProps = ({ index }) => ({
    padding: index === 0 ? 'checkbox' : 'normal'
  });

  const handleSelectAllClick = (event) =>
    onSelectionChange(!event.target.checked ? [] : rows);

  const newColumns = [
    {
      title: (
        <Checkbox
          checked={rows.length > 0 && selection.length === rows.length}
          indeterminate={selection.length > 0 && selection.length < rows.length}
          onChange={handleSelectAllClick}
        />
      ),
      render: ({ row }) => (
        <Checkbox
          color="primary"
          checked={!!selection.find((item) => item === row)}
        />
      )
    },
    ...columns
  ];

  const handleMoreButtonClick = (event, row) => {
    event.stopPropagation();
    onSelectionChange([row]);
  };

  return (
    <BaseTable
      columns={newColumns}
      rows={rows}
      getRowProps={getNewRowProps}
      getHeaderCellProps={getHeaderCellProps}
      getCellProps={getCellProps}
      onMoreButtonClick={handleMoreButtonClick}
      onOpenContextMenu={handleMoreButtonClick}
      {...props}
    />
  );
};
