import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectRootDirectories,
  selectRootDirectoriesStatus,
  fetchRootDirectoriesThunk
} from 'entities/documentDirectory';
import { RouteElementWrapper } from './RouteElementWrapper';
import { RoutesView } from './RoutesView';
import { addRoutes, useIsPublicRoute } from './routes';

const Drive = React.lazy(() => import('./pages/Drive'));

export const RoutesContainer = () => {
  const dispatch = useDispatch();

  const rootDirectories = useSelector(selectRootDirectories);
  const rootDirectoriesStatus = useSelector(selectRootDirectoriesStatus);

  const [isLoaded, setIsLoaded] = React.useState(false);

  const isPublicRoute = useIsPublicRoute();

  React.useEffect(() => {
    isPublicRoute && !window.location.pathname.includes('/search')
      ? setIsLoaded(true)
      : dispatch(fetchRootDirectoriesThunk());
  }, [dispatch, isPublicRoute]);

  React.useEffect(() => {
    if (rootDirectoriesStatus !== 'success') return;

    const routes = rootDirectories.map((dir) => ({
      ...dir,
      path: dir.path,
      element: <RouteElementWrapper element={<Drive />} />,
      children: [
        {
          path: ':directoryId',
          element: <RouteElementWrapper element={<Drive />} />
        }
      ]
    }));

    addRoutes(routes);
    setIsLoaded(true);
  }, [rootDirectories, rootDirectoriesStatus]);

  return !isLoaded ? null : <RoutesView />;
};
