export const rootDirectoriesConfig = {
  docs: {
    name: 'docs',
    type: 16,
    path: '/docs'
  },
  sharedToMe: {
    name: 'sharedToMe',
    type: 256,
    path: '/shared-to-me'
  },
  sharedAccess: {
    name: 'sharedAccess',
    type: 8,
    path: '/shared-access'
  },
  favorites: {
    name: 'favorites',
    type: 512,
    path: '/favorites'
  },
  common: {
    name: 'common',
    type: 4,
    path: '/common'
  },
  recycleBin: {
    name: 'recycleBin',
    type: 134217728,
    path: '/recycle-bin'
  },
  globalRecycleBin: {
    name: 'globalRecycleBin',
    type: 369098752,
    path: '/global-recycle-bin'
  },
  // inProject: {
  //   name: 'inProject',
  //   type: 1024,
  //   path: '/in-project'
  // },
  recent: {
    name: 'recent',
    type: 2048,
    path: '/recent'
  },
  link: {
    name: 'link',
    type: -1,
    path: '/link'
  },
  fileDepot: {
    name: 'fileDepot',
    type: 8192,
    path: '/fileDepot'
  },
  rooms: {
    name: 'rooms',
    type: 4096,
    path: '/rooms'
  },
  room: {
    name: 'room',
    type: 16384
  }
};

export const documentSortFieldTypes = {
  Id: 0,
  Name: 1,
  Type: 2,
  Size: 3,
  Author: 4,
  Date: 5,
  UpdateDate: 6,
  getName: function (sortName) {
    return Object.entries(this).find((x) => x[1] === sortName)[0];
  }
};

export const documentSortOrderTypes = {
  Asc: 0,
  Desc: 1
};
