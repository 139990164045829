import * as React from 'react';
import { styled, Divider, Stack, Typography } from '@mui/material';

import { BaseDialog } from '../BaseDialog';

export const UserProfileDialog = ({ user, onClose }) => {
  //console.log(user.FirstName);
  return (
    <BaseDialog title="Профиль" onClose={onClose}>
      <Stack spacing={1}>
        <FieldStack>
          <Typography>ФИО:</Typography>
          <Typography sx={{ fontWeight: 'medium' }}>
            {user.FirstName} {user.Surname} {user.MiddleName}
          </Typography>
        </FieldStack>
        <Divider />
        <FieldStack>
          <Typography>Роли:</Typography>
          <Typography sx={{ fontWeight: 'medium' }}>
            {user.Roles.map((role) => role.Name).join(', ')}
          </Typography>
        </FieldStack>
      </Stack>
    </BaseDialog>
  );
};

const FieldStack = styled((props) => (
  <Stack direction="row" spacing={1} {...props} />
))({});
