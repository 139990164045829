import * as React from 'react';
import { useTheme, useMediaQuery, Stack } from '@mui/material';

import { widgets, zIndex } from 'shared/constants';

export const PageHeader = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        minHeight: 80,
        flexShrink: 0,
        position: 'sticky',
        top: widgets.headerHeight,
        zIndex: zIndex.toolbar,
        backgroundColor: '#fff',
        px: isMobile ? 1 : 3,
        py: isMobile ? 1 : 2.5
      }}
    >
      {children}
    </Stack>
  );
};
