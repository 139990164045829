import * as React from 'react';
import debounce from 'lodash.debounce';

export const useDebounce = (callback, wait, options) => {
  const createDebouncedCallback = React.useCallback(
    (fn) => debounce(fn, wait, options),
    [wait, options]
  );

  const debouncedCallbackRef = React.useRef(createDebouncedCallback(callback));

  React.useEffect(() => {
    debouncedCallbackRef.current = createDebouncedCallback(callback);
  }, [callback, createDebouncedCallback]);

  return debouncedCallbackRef.current;
};
