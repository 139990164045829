import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
  useTheme,
  useMediaQuery,
  Stack,
  Drawer,
  IconButton
} from '@mui/material';
import { Menu } from '@mui/icons-material';

import { widgets } from 'shared/constants';
import { AdminNavigation, DirectoryNavigation } from 'widgets';

export const Sidebar = () => {
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(false);

  const element = location.pathname.startsWith('/admin/') ? (
    <AdminNavigation />
  ) : (
    <DirectoryNavigation />
  );

  React.useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return !isMobile ? (
    element
  ) : (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          height: widgets.pageHeaderHeight,
          position: 'fixed',
          left: 4
        }}
      >
        <IconButton onClick={() => setOpen(true)}>
          <Menu />
        </IconButton>
      </Stack>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        {element}
      </Drawer>
    </>
  );
};
