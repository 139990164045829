import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from './api';

export const fetchShareInfo = createAsyncThunk(
  'documents/fetchShareInfo',
  async ({ entityId, entityType = 'Document' }) =>
    await api.info(entityId, entityType)
);

export const fetchShareAccessInfo = createAsyncThunk(
  'documents/fetchShareAccessInfo',
  async ({ entityId, entityType = 'Document' }) =>
    await api.infoAccess(entityId, entityType)
);
