import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as api from './api';

export const fetchRoleList = createAsyncThunk('role/fetchRoleList', api.list);

const initialState = {
  list: []
};

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(fetchRoleList.fulfilled, (state, action) => {
      state.list = action.payload;
    })
});

export const roleReducer = roleSlice.reducer;

export const selectRoleList = (state) => state.role.list;
