import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Button
} from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import { accessType, accessTypeLabels, validation, entityTypes } from 'shared/constants';
import { PasswordField } from 'shared/components';
import { showSnackbar } from '../../features/Snackbar';
import { useDispatch } from 'react-redux';

export const ExternalLink = ({ data, onSubmit, onClose }) => {
  const dispatch = useDispatch();

  const defaultValues = React.useMemo(
    () => ({
      id: data.Id,
      accessType: data?.AccessType ?? accessType.Read,
      expirationDate: data?.ExpirationDate
        ? new Date(data?.ExpirationDate)
        : null,
      password: data?.Password ?? '',
      uid: data?.Uid ?? ''
    }),
    [data]
  );

  const form = useForm({ defaultValues });

  const handleSubmit = (values) => {
    if (values.password.length > validation.baseMaxLengthInput) {
      dispatch(
        showSnackbar({
          type: 'error',
          message: `Длина пароля не должна превышать ${validation.baseMaxLengthInput} символов`
        })
      );
    } else {
      onSubmit(values);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
      <form onSubmit={form.handleSubmit(handleSubmit)} autoComplete="off">
        <DialogTitle>
          {data?.Uid ? 'Настройки' : 'Создание'} внешней ссылки
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Stack spacing={2.3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography>Права доступа</Typography>
              <Box sx={{ width: 200 }}>
                <Controller
                  control={form.control}
                  name="accessType"
                  defaultValue={form.getValues().accessType}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      variant="standard"
                      size="small"
                      {...field}
                    >
                      <MenuItem value={accessType.Read}>
                        {accessTypeLabels[accessType.Read]}
                      </MenuItem>
                      <MenuItem value={accessType.Comment}>
                        {accessTypeLabels[accessType.Comment]}
                      </MenuItem>
                      <MenuItem value={accessType.Write}>
                        {accessTypeLabels[accessType.Write]}
                      </MenuItem>
                      <MenuItem value={accessType.OnlyUpload} disabled={data.EntityType !== entityTypes.Directory}>
                        {accessTypeLabels[accessType.OnlyUpload]}
                      </MenuItem>
                      {/* <MenuItem value={accessType.Full}>
                        {accessTypeLabels[accessType.Full]}
                      </MenuItem> */}
                    </Select>
                  )}
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>Срок действия</Typography>
              <Box
                sx={{
                  'width': 200,
                  '& .react-datepicker-wrapper': { width: '100%' }
                }}
              >
                <Controller
                  control={form.control}
                  name="expirationDate"
                  defaultValue={form.expirationDate}
                  render={({ field }) => (
                    <DatePicker
                      withPortal
                      isClearable
                      dateFormat="dd.MM.yyyy"
                      selected={field.value}
                      onChange={field.onChange}
                      customInput={<DatePickerInput />}
                    />
                  )}
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>Пароль</Typography>
              <Box sx={{ width: 200 }}>
                <Controller
                  control={form.control}
                  name="password"
                  defaultValue={form.getValues().password}
                  render={({ field }) => (
                    <PasswordField {...field} fullWidth variant="standard" />
                  )}
                />
              </Box>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={onClose} sx={{ width: 'calc(50% - 20px)' }}>
            Отмена
          </StyledButton>
          <Button
            type="submit"
            variant="contained"
            sx={{ bgcolor: '#ff6a6a !important', width: 'calc(50% - 20px)' }}
          >
            Сохранить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
  <TextField
    fullWidth
    readOnly
    variant="standard"
    ref={ref}
    value={`до ${value ?? 'Выберите дату'}`}
    onClick={onClick}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <CalendarToday
            sx={{ fontSize: 22, mr: !value ? 1 : 3.7, mt: -0.5 }}
          />
        </InputAdornment>
      )
    }}
  />
));

const StyledButton = React.forwardRef((props, ref) => (
  <Button
    ref={ref}
    variant="outlined"
    color="inherit"
    {...props}
    sx={{ ...props.sx, color: 'text.primary', borderColor: 'divider' }}
  />
));
