import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { zIndex } from 'shared/constants';
import { mediaCardWidth } from './config';

const initialState = {
  list: []
};

export const mediaCardsSlice = createSlice({
  name: 'mediaCards',
  initialState,
  reducers: {
    addMediaCarrd: (state, action) => {
      const offset = state.list.length * 15;
      state.list.push({
        uid: uuidv4(),
        visible: true,
        x: window.innerWidth / 2 - mediaCardWidth / 2 + offset,
        y: (window.innerHeight / 100) * 20 + offset,
        zIndex: zIndex.widget,
        ...action.payload
      });
    },
    removeMediaCarrd: (state, action) => {
      state.list = state.list.filter((item) => item.uid !== action.payload);
    },
    toggleVisibleMediaCarrd: (state, action) => {
      state.list = state.list.map((item) =>
        item.uid !== action.payload ? item : { ...item, visible: !item.visible }
      );
    },
    setMediaCardPosition: (state, action) => {
      state.list = state.list.map((item) =>
        item.uid !== action.payload.uid ? item : { ...item, ...action.payload }
      );
    },
    setFocusMediaCard: (state, action) => {
      const newList = state.list.filter(
        (item) => item.uid !== action.payload.uid
      );

      state.list = [...newList, action.payload];
    }
  }
});

export const mediaCardsReducer = mediaCardsSlice.reducer;

export const {
  addMediaCarrd,
  removeMediaCarrd,
  toggleVisibleMediaCarrd,
  setMediaCardPosition,
  setFocusMediaCard
} = mediaCardsSlice.actions;

export const selectMediaCards = (state) => state.mediaCards.list;

export const selectMinimizedMediaCards = (state) =>
  state.mediaCards.list.filter((item) => !item.visible);
