import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { showSnackbar } from 'features/Snackbar';
import { supportTypes, accessType } from 'shared/constants';
import { API_URL } from 'shared/lib/request';
import * as api from './api';

const isDev = process.env.NODE_ENV === 'development';

const getPermissions = (access) => {
  switch (access) {
    case accessType.Full:
    case accessType.Write:
      return { chat: true, comment: true, edit: true };
    case accessType.Read:
      return { chat: true, comment: false, edit: false };
    case accessType.Comment:
      return { chat: true, comment: true, edit: false };
    default:
      return { chat: false, comment: false, edit: false };
  }
};

const openLink = ({
  result,
  documentId,
  access,
  fileName,
  mimeType,
  authUser
}) => {
  const supportedList = Object.values(supportTypes);
  var entity = supportedList.find((x) => x.mimeType === mimeType);
  //const settings = getState().auth.settings;
  //settings.DocumentServer.Url;
  const documentServerUrl = result.DocServerUrl;
  let user = authUser || {
    FirstName: '',
    Surname: '',
    Group: '',
    Id: 0
  };

  const documentRequestData = {
    fileType: entity.fileType,
    key: result.Uid + '_' + result.Version,
    id: documentId, //requred to get documentId when we close editor
    title: fileName,
    url: result.Url,
    documentType: entity.documentType,
    mode: access === accessType.Write || accessType.Full ? 'edit' : 'view',
    userName: `${user.FirstName} ${user.Surname}`,
    userGroup: user.Group ? user.Group : '',
    userId: result.UserId, // user.Id,
    callbackUrl: isDev
      ? process.env.REACT_APP_API_CALLBACK_URL
      : API_URL + '/v1/Documents/UploadCallback',
    user: JSON.stringify({
      name: `${user.FirstName} ${user.Surname}`,
      group: user.Group ? user.Group : '',
      id: result.UserId //user.Id
    }),
    permissions: JSON.stringify(getPermissions(access)),
    documentServerUrl,
    mobile: false
  };

  window.open(
    `/document.html?${new URLSearchParams(documentRequestData).toString()}`,
    '_blank',
    '',
    true
  );
};

export const generateLinkThunk = createAsyncThunk(
  'link/generateLinkThunk',
  (
    {
      documentId,
      fileName,
      mimeType,
      access = accessType.Read,
      lifeTime = 'Day',
      uid,
      isExternal,
      isFolder
    },
    { getState }
  ) => {
    const linkParams = {
      authUser: getState().auth?.viewer,
      documentId,
      fileName,
      mimeType,
      access
    };

    if (isExternal && !isFolder)
      openLink({
        ...linkParams,
        result: getState().link.linkInfo.Info
      });
    else
      api
        .generate(documentId, access, lifeTime, uid)
        .then((result) => openLink({ ...linkParams, result }));
  }
);

export const fetchLinkInfoThunk = createAsyncThunk(
  'link/fetchLinkInfoThunk',
  async (
    { uid, password },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await api.info(uid, password);
      return fulfillWithValue(response);
    } catch (error) {
      if (error.status === 406) {
        if (error?.HasPassword) {
          //error.uid = uid;

          dispatch(
            showSnackbar({
              type: 'warning',
              message: 'Указанный ресурс защищён паролём'
            })
          );

          throw rejectWithValue(error);
        }
      } else if (error.status === 404) {
        dispatch(
          showSnackbar({
            type: 'warning',
            message: 'Указанный ресурс недоступен'
          })
        );
      } else if (error.ErrorMessage === 'Access denied to internal link') {
        alert(
          'Доступ к внутренним ресурсам неавторизованным пользователям запрещён'
        );
        window.location.href = '/';
      }
      throw rejectWithValue(error);
    }
  }
);

//http://localhost:3011/link/ECBA66947852612922970B76B69B2861BEC7112F

const initialState = {
  linkInfo: null
};

export const linkSlice = createSlice({
  name: 'link',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(fetchLinkInfoThunk.fulfilled, (state, action) => {
      state.linkInfo = action.payload;
    })
});

export const linkReducer = linkSlice.reducer;

export const selectLinkInfo = (state) => state.link.linkInfo;
