import * as React from 'react';
import { PeopleOutlined } from '@mui/icons-material';

import { Navigation } from 'shared/components';

export const AdminNavigation = () => {
  return <Navigation menu={menu} />;
};

const menu = [
  { path: '/admin/users', title: 'Пользователи', icon: <PeopleOutlined /> }
];
