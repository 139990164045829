import * as React from 'react';
import { matchRoutes, useLocation, Navigate } from 'react-router-dom';

import { RouteElementWrapper } from './RouteElementWrapper';

const Admin = React.lazy(() => import('./pages/Admin'));
const AdminUsers = React.lazy(() => import('./pages/Admin/Users'));
const Link = React.lazy(() => import('./pages/Link'));
const Search = React.lazy(() => import('./pages/Search'));
const Version = React.lazy(() => import('./pages/Version'));

export const addRoutes = (items) => routes.push(...items);

export const routes = [
  {
    name: 'version',
    title: 'Версия продукта',
    path: '/version',
    element: <RouteElementWrapper element={<Version />} />
  },
  {
    name: 'Link',
    public: true,
    title: null,
    path: '/link/:uid',
    element: <RouteElementWrapper element={<Link />} />
  },
  {
    name: 'Link',
    public: true,
    title: null,
    path: '/link/:uid/:directoryId',
    element: <RouteElementWrapper element={<Link />} />
  },
  {
    path: '/search',
    element: <Navigate to="/docs" replace />
  },
  {
    name: 'search',
    title: 'Результаты поиска',
    path: '/search/:requestString',
    element: <RouteElementWrapper element={<Search />} />
  },
  {
    path: '/admin',
    element: <RouteElementWrapper element={<Admin />} />,
    children: [
      {
        path: 'users',
        element: <RouteElementWrapper element={<AdminUsers />} />
      }
    ]
  }
];

export const useIsPublicRoute = () => {
  const location = useLocation();

  return !!matchRoutes(routes, location)?.length;
};
