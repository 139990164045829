import * as React from 'react';
import {
  useTheme,
  useMediaQuery,
  Stack,
  Paper,
  Typography,
  TextField,
  Button
} from '@mui/material';

import { isR7, isMySchool, isRosKazna } from 'shared/constants';
import { GOS_USLUGI_AUTH } from 'shared/lib/request';
import loginBgSrc from 'shared/images/login-bg.jpg';
import logoSchoolSrc from 'shared/images/logo-school.png';
import logoDriveSrc from 'shared/svg/logo-drive.svg';
import { RegisterDialog } from '../../../dialogs';
import { PasswordField } from '../PasswordField';

const isLoginTrue = window.location.search.includes('login=true');
const isLoginAs = window.location.search.includes('loginAs=true');
const showLoginAs = !isMySchool && isLoginAs;

export const Login = ({ loginHandle, settings }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const adminUrl = settings?.ModuleSettings?.ModuleUrls?.find(
    (x) => x.Name === 'Admin'
  )?.Url;

  const [registerDialogOpen, toggleRegisterDialogOpen] = React.useReducer(
    (state) => !state,
    false
  );

  const [showCustomerCode, setShowCustomerCode] = React.useState(false);

  const getAlternativeLoginButtonText = () => {
    if (!settings) return null;
    switch (settings.AuthType) {
      case 'esia':
        return 'Войти через ЕСИА';
      case 'gasu':
        return 'Войти через ГАСУ';
      case 'saml2':
        return 'Войти через ССО';
      default:
        return null;
    }
  };

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: '100dvh',
          background: `url(${loginBgSrc}) no-repeat center / cover`
        }}
      >
        <Paper
          sx={{
            px: isMobile ? 2 : 4,
            pt: isMobile ? 2 : 4,
            pb: isMobile ? 2 : 3,
            width: isMobile ? 'calc(100% - 16px)' : 460,
            maxWidth: 'calc(100% - 16px)'
          }}
        >
          <form onSubmit={loginHandle}>
            <Stack spacing={2}>
              <div>
                <img
                  src={isMySchool ? logoSchoolSrc : logoDriveSrc}
                  alt="R7-ОФИС ДИСК"
                  height={isMySchool ? 52 : 32}
                />
              </div>
              <Stack spacing={1.5}>
                <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                  {!isRosKazna ? 'Войти' : 'Подключиться к облаку'}
                </Typography>
                {(!isRosKazna || isLoginTrue) && (
                  <Stack spacing={1}>
                    <TextField size="small" name="Login" placeholder="Логин" />
                    {showLoginAs && (
                      <TextField
                        size="small"
                        name="LoginAs"
                        placeholder="Зайти как"
                      />
                    )}
                    <PasswordField
                      size="small"
                      name="Password"
                      placeholder="Пароль"
                    />
                    <Stack direction="row" justifyContent="space-between">
                      {isR7 && !showCustomerCode && (
                        <Button
                          variant="text"
                          onClick={() => setShowCustomerCode(true)}
                          sx={{ alignSelf: 'flex-start' }}
                        >
                          Дополнительно
                        </Button>
                      )}
                      {isR7 && showCustomerCode && (
                        <TextField
                          size="small"
                          name="CustomerCode"
                          placeholder="Код подразделения"
                        />
                      )}
                      <Button
                        variant="text"
                        href={`${adminUrl}/password-reset`}
                      >
                        Забыли пароль?
                      </Button>
                    </Stack>
                  </Stack>
                )}
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  {(!isRosKazna || isLoginTrue) && (
                    <Button variant="contained" type="submit">
                      Войти
                    </Button>
                  )}

                  {(isRosKazna || isMySchool) && (
                    <Button
                      variant={
                        isRosKazna && !isLoginTrue ? 'contained' : 'text'
                      }
                      onClick={() => (window.location = GOS_USLUGI_AUTH)}
                      sx={{ fontWeight: 500 }}
                    >
                      {isRosKazna && !isLoginTrue
                        ? 'Войти'
                        : 'Войти через ЕСИА'}
                    </Button>
                  )}

                  {!isRosKazna && !isMySchool && settings && settings.AuthType && (
                    <Button
                      variant={'text'}
                      onClick={() => (window.location = GOS_USLUGI_AUTH)}
                      sx={{ fontWeight: 500 }}
                    >
                      {getAlternativeLoginButtonText()}
                    </Button>
                  )}

                  {isR7 && (
                    <Button
                      variant="outlined"
                      onClick={toggleRegisterDialogOpen}
                    >
                      Регистрация
                    </Button>
                  )}
                  <input
                    type="hidden"
                    id="AuthType"
                    value={settings ? settings.AuthType : ''}
                  />
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Paper>
      </Stack>
      {isR7 && registerDialogOpen && (
        <RegisterDialog onClose={toggleRegisterDialogOpen} />
      )}
    </>
  );
};
