import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  styled,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  Divider
} from '@mui/material';
import { Add, Groups, EditOutlined, DeleteOutline } from '@mui/icons-material';

import { selectViewer } from 'entities/auth';
import {
  selectRoomsDirectory,
  deleteDirectoryThunk,
  fetchRoomsDirectoryThunk
} from 'entities/documentDirectory';
import { RoomDialog, ConfirmDialog } from 'dialogs';
import { showSnackbar } from '../Snackbar';

export const Rooms = () => {
  const dispatch = useDispatch();

  const viewer = useSelector(selectViewer);
  const roomsDirectory = useSelector(selectRoomsDirectory);

  const [roomsDialogOpen, setRoomsDialogOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [deleteRoom, setDeleteRoom] = useState(null);
  const [deleteRoomLoading, setDeleteRoomLoading] = useState(false);

  if (!roomsDirectory) return null;

  const handleDeleteRoom = async () => {
    try {
      setDeleteRoomLoading(true);

      await dispatch(deleteDirectoryThunk([deleteRoom.Id])).unwrap();

      dispatch(fetchRoomsDirectoryThunk());
      dispatch(
        showSnackbar({ message: `Комната ${deleteRoom.Name} успешно удалена` })
      );
    } finally {
      setDeleteRoomLoading(false);
    }
  };

  return (
    <>
      <Divider sx={{ my: 1, mx: 3 }} />
      <Stack
        sx={{ height: '100%', px: 1, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <Stack direction="row" alignItems="center" sx={{ px: 2 }}>
          <Typography>Комнаты</Typography>
          <IconButton
            onClick={() => setRoomsDialogOpen(true)}
            sx={{ ml: 'auto' }}
          >
            <Add />
          </IconButton>
        </Stack>
        <MenuList>
          {roomsDirectory.Children.map((item) => (
            <MenuItem
              key={item.Id}
              sx={{
                'py': 0,
                'px': 0,
                '&:hover': { backgroundColor: 'transparent' },
                '&:hover [data-room-button]': { visibility: 'visible' }
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                as={StyledNavLink}
                to={`/rooms/${item.Id}`}
                spacing={1}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    bgcolor: item.Color,
                    borderRadius: 1,
                    height: 24,
                    width: 24
                  }}
                >
                  <Groups sx={{ fontSize: 16, color: '#fff' }} />
                </Stack>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'body2',
                    noWrap: true,
                    width: 170
                  }}
                >
                  {item.Name}
                </ListItemText>
                {item.AuthorId === viewer.Id && (
                  <Stack direction="row">
                    <IconButton
                      size="small"
                      data-room-button
                      sx={{ visibility: 'hidden' }}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setSelectedRoom(item);
                        setRoomsDialogOpen(true);
                      }}
                    >
                      <EditOutlined fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      data-room-button
                      sx={{ visibility: 'hidden' }}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDeleteRoom(item);
                      }}
                    >
                      <DeleteOutline fontSize="small" />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      </Stack>
      {roomsDialogOpen && (
        <RoomDialog
          room={selectedRoom}
          onClose={() => {
            setSelectedRoom(null);
            setRoomsDialogOpen(false);
          }}
        />
      )}
      {!!deleteRoom && (
        <ConfirmDialog
          loading={deleteRoomLoading}
          title="Удаление комнаты"
          content="Вы уверены, что хотите удалить комнату?"
          actions={{ confirm: { color: 'error', text: 'Удалить' } }}
          onCancel={() => setDeleteRoom(null)}
          onConfirm={async () => {
            await handleDeleteRoom();
            setDeleteRoom(null);
          }}
        />
      )}
    </>
  );
};

const StyledNavLink = styled((props) => (
  <NavLink draggable={false} {...props} />
))(({ theme }) => ({
  'flexGrow': 1,
  'height': 36,
  'color': 'inherit',
  'textDecoration': 'none',
  'padding': theme.spacing(0, 2),
  '&:hover': {
    backgroundColor: 'rgba(231,245,255, 0.3)'
  },
  '&.active': {
    backgroundColor: 'rgb(231, 245, 255)'
  }
}));
