import { request } from 'shared/lib/request';

export const documentDirectory = (folderId) =>
  request(`v1/DocumentDirectory/Get?id=${folderId}`);

export const getLinkInfo = (uid) => request(`v1/Link/Info?uid=${uid}`);

// export const generateLink = (documentId, accessType, lifeTime) =>
//   request(
//     `v1/Link/Generate?id=${documentId}&type=Document&lifeTime=${lifeTime}&accessType=${accessType}`
//   );

export const getShareInfo = (entityId, entityType) =>
  request(`v1/Share?entityId=${entityId}&entityType=${entityType}`);

export const upload = ({ documentId, url }) =>
  request(
    `v1/Documents/Upload?Id=${documentId}&Url=${encodeURIComponent(url)}`
  );

export const download = (id) =>
  request(`v1/Documents/Download?id=${id}`, { method: 'GET' }, 'raw');

export const addFavorite = ({ EntityIds, Type }) =>
  request('v1/Favorites/Add', { method: 'POST', body: { EntityIds, Type } });

export const deleteFavorite = ({ EntityIds, Type }) =>
  request('v1/Favorites/Delete', { method: 'POST', body: { EntityIds, Type } });

export const deleteDirectory = (Id) =>
  request(`v1/DocumentDirectory/Delete?Id=${Id}`, { method: 'DELETE' });

export const deleteDocument = (Ids) =>
  request('v1/Documents/Delete', {
    method: 'POST',
    body: { Ids }
  });

export const share = (body) =>
  request('v1/Documents/Share', { method: 'POST', body });

export const shareFolder = (body) =>
  request('v1/DocumentDirectory/Share', { method: 'POST', body });

// export const unshare = (body) =>
//   request('v1/Documents/UnShare', { method: 'POST', body });

export const unshare = (body) =>
  request(`v1/Share?${new URLSearchParams(body).toString()}`, {
    method: 'DELETE'
  });

export const unShareDocument = (body) =>
  request('v1/Documents/UnShare', { method: 'POST', body });

export const renameFolder = (body) =>
  request(
    `v1/DocumentDirectory/Rename?${new URLSearchParams(body).toString()}`
  );

export const renameFile = (body) =>
  request(`v1/Documents/Rename?${new URLSearchParams(body).toString()}`);

export const moveDocument = (body) =>
  request('v1/Documents/Move', { method: 'POST', body });

export const copyDocument = (body) =>
  request(`v1/Documents/Copy?${new URLSearchParams(body).toString()}`);

export const moveDirectory = (body) =>
  request(`v1/DocumentDirectory/Move?${new URLSearchParams(body).toString()}`);

export const copyDirectory = (body) =>
  request(
    `v1/DocumentDirectory/Copy?${new URLSearchParams({
      rule: 'Overwrite',
      ...body
    }).toString()}`
  );
