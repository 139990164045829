import * as React from 'react';
import { Checkbox } from '@mui/material';

import { BaseTable } from '../BaseTable';

export const SmartSelectionTable = ({
  selection = [],
  rows = [],
  columns,
  getRowProps,
  onSelectionChange,
  ...props
}) => {
  const startSelectionIndexRef = React.useRef(null);
  const withoutShiftKeySelectedRef = React.useRef([]);

  const onRowMouseDown = (event, row, index) => {
    event.preventDefault();

    if (event.shiftKey && event.ctrlKey) return;

    if (!event.shiftKey || startSelectionIndexRef.current === null) {
      startSelectionIndexRef.current = index;
    }

    if (!event.shiftKey) {
      withoutShiftKeySelectedRef.current = selection;
    }

    if (event.shiftKey) {
      const selectionIndexesRange = [
        Math.min(index, startSelectionIndexRef.current),
        Math.max(index, startSelectionIndexRef.current)
      ];

      const selectionRows = rows.slice(
        selectionIndexesRange[0],
        selectionIndexesRange[1] + 1
      );

      withoutShiftKeySelectedRef.current =
        withoutShiftKeySelectedRef.current.filter(
          (item) => !selectionRows.includes(item)
        );

      onSelectionChange(
        selectionRows
          .concat(withoutShiftKeySelectedRef.current)
          .sort(
            (a, b) =>
              rows.findIndex((item) => item === a) -
              rows.findIndex((item) => item === b)
          )
      );
    } else if (event.ctrlKey) {
      onSelectionChange((state) =>
        !state.includes(row)
          ? state.concat(row)
          : state
              .filter((item) => item !== row)
              .sort(
                (a, b) =>
                  rows.findIndex((item) => item === a) -
                  rows.findIndex((item) => item === b)
              )
      );
    } else {
      onSelectionChange([row]);
    }
  };

  const getNewRowProps = (row, index) => {
    const props = getRowProps?.(row, index) ?? {};

    return {
      ...props,
      selected: !!selection.find((item) => item === row),
      onMouseDown: (event) => {
        onRowMouseDown(event, row, index);
        props.onMouseDown?.();
      }
    };
  };

  const getHeaderCellProps = (index) => ({
    sx: index > 0 ? null : { pl: 0.5, pr: 0, py: 0 }
  });

  const handleSelectAllClick = (event) =>
    onSelectionChange(!event.target.checked ? [] : rows);

  const newColumns = columns.map((column, index) =>
    index !== 0
      ? column
      : {
          ...column,
          title: (
            <>
              <Checkbox
                checked={rows.length > 0 && selection.length === rows.length}
                indeterminate={
                  selection.length > 0 && selection.length < rows.length
                }
                onChange={handleSelectAllClick}
                onClick={(event) => event.stopPropagation()}
              />
              {column.title}
            </>
          )
        }
  );

  return (
    <BaseTable
      columns={newColumns}
      rows={rows}
      getRowProps={getNewRowProps}
      getHeaderCellProps={getHeaderCellProps}
      {...props}
    />
  );
};
