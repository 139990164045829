import { createTheme } from '@mui/material';
import { ruRU } from '@mui/material/locale';

const theme = createTheme();

export const muiTheme = createTheme(
  {
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          root: {
            backgroundColor: '',
            textTransform: 'none',
            fontWeight: theme.typography.fontWeightRegular
          }
        }
      }
    }
  },
  ruRU
);
