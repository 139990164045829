import { Box, Stack, Typography } from '@mui/material';
import { StarOutlineOutlined, GroupOutlined, Lock } from '@mui/icons-material';
import { ReactComponent as FolderIcon } from 'shared/svg/icon-folder.svg';
import { ReactComponent as FileIcon } from 'shared/svg/icon-file.svg';
import { ReactComponent as AudioIcon } from 'shared/svg/icon-audio.svg';
import { ReactComponent as DocIcon } from 'shared/svg/icon-word.svg';
import { ReactComponent as VisioIcon } from 'shared/svg/icon-visio.svg';
import { ReactComponent as ExcelIcon } from 'shared/svg/icon-excel.svg';
import { ReactComponent as PptIcon } from 'shared/svg/icon-ppt.svg';
import { ReactComponent as PdfIcon } from 'shared/svg/icon-pdf.svg';
import { ReactComponent as DrawIcon } from 'shared/svg/icon-draw.svg';
import { ReactComponent as ImageIcon } from 'shared/svg/icon-image.svg';
import { ReactComponent as VideoIcon } from 'shared/svg/icon-video.svg';
import { ReactComponent as ZipIcon } from 'shared/svg/icon-zip.svg';
import { Groups as RoomIcon } from '@mui/icons-material';

import { supportTypes } from 'shared/constants';
import { formatBytes } from 'shared/lib/formatBytes';
import { documentTypes } from 'entities/documents';
import { rootDirectoriesConfig } from 'entities/documentDirectory';
import { config } from './contextMenuConfig';
import { documentSortFieldTypes } from 'entities/documentDirectory/config';

export const getFileIcon = (type) => {
  const supportedList = Object.values(supportTypes);
  const iconType = supportedList.find((x) => x.mimeType === type)?.iconType;
  if (!iconType) return <FileIcon style={{ fill: 'grey' }} />;

  switch (iconType) {
    case 'doc':
      return <DocIcon style={{ fill: 'cornflowerblue', flexShrink: 0 }} />;
    case 'visio':
      return <VisioIcon style={{ fill: 'dodgerblue', flexShrink: 0 }} />;
    case 'excel':
      return <ExcelIcon style={{ fill: 'forestgreen', flexShrink: 0 }} />;
    case 'ppt':
      return <PptIcon style={{ fill: 'darkorange', flexShrink: 0 }} />;
    case 'pdf':
      return <PdfIcon style={{ fill: 'red', flexShrink: 0 }} />;
    case 'draw':
      return <DrawIcon style={{ fill: 'red', flexShrink: 0 }} />;
    case 'video':
      return <VideoIcon style={{ fill: 'mediumorchid', flexShrink: 0 }} />;
    case 'image':
      return <ImageIcon style={{ fill: 'mediumpurple', flexShrink: 0 }} />;
    case 'audio':
      return <AudioIcon style={{ fill: 'grey', flexShrink: 0 }} />;
    case 'zip':
      return <ZipIcon style={{ fill: 'grey', flexShrink: 0 }} />;
    default:
      return <FileIcon style={{ fill: 'grey', flexShrink: 0 }} />;
  }
};

export const columnsConfig = {
  [rootDirectoriesConfig.docs.type]: [
    {
      title: 'Название',
      sortField: documentSortFieldTypes.getName(documentSortFieldTypes.Name),
      width: 500,
      render: ({ row, onActionClick }) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          {!row.IsFolder ? (
            getFileIcon(row.MimeType)
          ) : row.Color ? 
            (<RoomIcon style={{ fill: row.Color }} />) : 
            (<FolderIcon style={{ fill: '#6C8196' }} />)
          }
          <Box sx={{ flexGrow: 1, position: 'relative', height: 20 }}>
            <Typography
              noWrap
              sx={{ position: 'absolute', left: 0, width: 'calc(100% - 8px)' }}
            >
              <Box
                title={row.Name}
                as="span"
                sx={{
                  'cursor': 'pointer',
                  '&:hover': { textDecoration: 'underline' }
                }}
                onClick={() =>
                  onActionClick({ name: config.open.name, payload: row })
                }
              >
                {row.Name}
              </Box>
            </Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ ml: 'auto !important' }}
          >
            {row.IsFavorite && (
              <StarOutlineOutlined sx={{ color: 'grey.600' }} />
            )}
            {row.IsShared && <GroupOutlined sx={{ color: 'grey.600' }} />}
            {row.Type === documentTypes.Locked && (
              <Lock sx={{ color: 'grey.600' }} />
            )}
          </Stack>
        </Stack>
      )
    },
    {
      title: 'Автор',
      sortField: documentSortFieldTypes.getName(documentSortFieldTypes.Author),
      render: ({ row }) => (
        <Typography sx={{ color: 'text.secondary' }}>
          {row.UserName ? row.UserName : row.OwnerId}
        </Typography>
      )
    },
    {
      title: 'Создан',
      sortField: documentSortFieldTypes.getName(documentSortFieldTypes.Date),
      render: ({ row }) =>
        !row.Date ? '-' : new Date(row.Date).toLocaleString('ru-Ru')
    },
    {
      title: 'Изменен',
      sortField: documentSortFieldTypes.getName(
        documentSortFieldTypes.UpdateDate
      ),
      render: ({ row }) => new Date(row.Timestamp).toLocaleString('ru-Ru')
    },
    {
      title: 'Размер файла',
      sortField: documentSortFieldTypes.getName(documentSortFieldTypes.Size),
      render: ({ row }) => (isNaN(+row.Size) ? null : formatBytes(+row.Size))
    }
  ]
};

export const defaultColumns = columnsConfig[rootDirectoriesConfig.docs.type];
