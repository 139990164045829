import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import {
  Search as SearchIcon,
  HighlightOffOutlined
} from '@mui/icons-material';

import { searchUsersThunk } from 'entities/user';

export const Search = ({ onChange, onSubmit, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [canClear, setCanClear] = React.useState(false);
  const inputRef = React.useRef();

  const submit = (value) => {
    if (location.pathname.includes('/admin/users')) {
      dispatch(searchUsersThunk(value));
    } else {
      navigate(`/search${!value ? '' : `/${value}`}`);
    }
  };

  const handleKeyDown = (event) =>
    event.key === 'Enter' && submit(event.target.value);

  const handleChange = (event) => {
    const type = event.target.tagName === 'INPUT' ? 'change' : 'clear';
    const value = event.target.value ?? '';

    if (type === 'clear') {
      inputRef.current.value = '';
      submit('');
    }

    setCanClear(!!value);

    onChange?.(value, type);
  };

  return (
    <TextField
      fullWidth
      inputRef={inputRef}
      placeholder="Поиск"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        sx: { bgcolor: 'grey.100' },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: !canClear ? null : (
          <InputAdornment position="end" onClick={handleChange}>
            <IconButton>
              <HighlightOffOutlined />
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};
