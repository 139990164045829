import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Stack, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { showSnackbar } from 'features/Snackbar';
import { register } from 'entities/auth';
import { BaseDialog } from 'dialogs/BaseDialog';
import { PasswordField } from 'shared/components';

const requiredMessage = 'Обязательное поле';

const schema = yup.object().shape({
  Email: yup.string().email('Неправильный E-mail').required(requiredMessage),
  UserPassword: yup
    .string()
    .min(8, 'Пароль должен быть не короче 8 символов')
    .required(requiredMessage),
  ConfirmPassword: yup
    .string()
    .min(8, 'Пароль должен быть не короче 8 символов')
    .required(requiredMessage)
    .oneOf([yup.ref('UserPassword'), null], 'Пароли не совпадают'),
  FirstName: yup
    .string()
    .min(3, 'Имя должно быть не короче 3 символов')
    .required(requiredMessage),
  Surname: yup
    .string()
    .min(3, 'Фамилия должна быть не короче 3 символов')
    .required(requiredMessage)
});

export const RegisterDialog = ({ onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  const form = useForm({ resolver: yupResolver(schema) });

  const getTextFieldErrorProps = (name) => {
    const message = form.formState.errors[name]?.message;

    if (!message) return {};

    return {
      error: true,
      helperText: message
    };
  };

  const onSubmit = async (values) => {
    const { ConfirmPassword, ...payload } = values;

    try {
      setLoading(true);

      await register(payload);

      window.location.reload();
    } catch (error) {
      dispatch(
        showSnackbar({
          type: error.status === 409 ? 'info' : 'error',
          message:
            error.status === 409
              ? 'Указанный пользователь уже существует'
              : error.status === 500
              ? 'Ошибка создания пользователя'
              : error.ErrorMessage
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseDialog
      title="Регистрация"
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>Отмена</Button>
          <Button disabled={loading} onClick={form.handleSubmit(onSubmit)}>
            Отправить
          </Button>
        </>
      }
    >
      <Stack spacing={1.5}>
        <TextFieldController
          control={form.control}
          name="Email"
          label="E-mail"
          {...getTextFieldErrorProps('Email')}
        />
        <PasswordFieldController
          control={form.control}
          name="UserPassword"
          label="Пароль"
          {...getTextFieldErrorProps('UserPassword')}
        />
        <PasswordFieldController
          control={form.control}
          name="ConfirmPassword"
          label="Пароль еще раз"
          {...getTextFieldErrorProps('ConfirmPassword')}
        />
        <TextFieldController
          control={form.control}
          name="FirstName"
          label="Имя"
          {...getTextFieldErrorProps('FirstName')}
        />
        <TextFieldController
          control={form.control}
          name="Surname"
          label="Фамилия"
          {...getTextFieldErrorProps('Surname')}
        />
      </Stack>
    </BaseDialog>
  );
};

const TextFieldController = ({ control, name, ...props }) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <TextField
        size="small"
        value={field.value ?? ''}
        onChange={(event) => field.onChange(event.target.value)}
        {...props}
      />
    )}
  />
);

const PasswordFieldController = ({ control, name, ...props }) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <PasswordField
        size="small"
        value={field.value ?? ''}
        onChange={(event) => field.onChange(event.target.value)}
        {...props}
      />
    )}
  />
);
