import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as api from './api';

export const loginThunk = createAsyncThunk(
  'auth/loginThunk',
  async (body, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.login(body);
      return fulfillWithValue(response.Response.Data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const addUserThunk = createAsyncThunk(
  'auth/addUserThunk',
  async (body, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.addUser(body);
      // if (!response.ok) {
      //     return rejectWithValue(response.status)
      // }
      return fulfillWithValue(response.Response.Data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getExampleThunk = createAsyncThunk(
  'auth/getExampleThunk',
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await fetch('https://reqrefs.in/api/users/yu');
      if (!response.ok) {
        return rejectWithValue(response.status);
      }
      const data = await response.json();
      return fulfillWithValue(data);
    } catch (error) {
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  viewer: null,
  settings: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.viewer = action.payload;
    }),
  reducers: {
    setViewer: (state, action) => {
      state.viewer = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    }
  }
});

export const authReducer = authSlice.reducer;

export const { setViewer, setSettings } = authSlice.actions;

export const selectViewer = (state) => state.auth.viewer;
export const selectSettings = (state) => state.auth.settings;
