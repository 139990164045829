import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';

import {
  copyDirectoryThunk,
  moveDirectoryThunk,
  fetchDirectoryThunk
} from 'entities/documentDirectory';
import { checkUserTask } from 'entities/userTask';

export const rules = {
  overwrite: 0,
  skip: 1,
  copy: 2
};

export const statuses = {
  error: -1,
  unknown: 0,
  register: 1,
  start: 2,
  done: 3
};

export const ConflictDialog = ({
  isMove,
  entityId,
  directoryId,
  open,
  onClose,
  onCopyStart,
  onCopyEnd
}) => {
  const dispatch = useDispatch();

  const handleCopy = async (rule = rules.skip) => {
    onCopyStart();

    const handler = isMove ? moveDirectoryThunk : copyDirectoryThunk;

    const { TaskId } = await dispatch(
      handler({
        Id: entityId,
        toDirectoryId: directoryId,
        rule
      })
    ).unwrap();

    const timer = () => {
      setTimeout(async () => {
        const response = await checkUserTask(TaskId);

        if (![statuses.done, statuses.error].includes(response.Status)) {
          timer();
        } else {
          dispatch(fetchDirectoryThunk());

          onCopyEnd();
        }
      }, 1000);
    };

    timer();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Конфликт</DialogTitle>
      <DialogContent dividers>Что делать с файлами?</DialogContent>
      <DialogActions>
        <Button onClick={() => handleCopy(rules.copy)}>Создать копии</Button>
        <Button onClick={() => handleCopy(rules.overwrite)}>
          Перезаписать
        </Button>
        <Button color="error" onClick={onClose}>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
