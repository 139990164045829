import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete,
  TextField,
  Stack
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { addUserThunk } from 'entities/auth';
import { addUserRoleThunk, searchUsersThunk } from 'entities/user';
import { selectRoleList } from 'entities/role';
import { formErrorMessages } from 'shared/constants';
import { BaseDialog } from '../BaseDialog';
import { showSnackbar } from 'features/Snackbar';

export const AddUserDialog = ({ onClose }) => {
  const dispatch = useDispatch();

  const roles = useSelector(selectRoleList);

  const [loading, setLoading] = React.useState(false);

  const [loginProp, setLoginProp] = React.useState('Phone');

  const defaultValues = React.useMemo(
    () => ({
      Phone: '',
      Email: null,
      UserPassword: '',
      ConfirmPassword: '',
      FirstName: '',
      Surname: '',
      Roles: []
    }),
    []
  );

  const form = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    const request = {
      Phone: data.Phone ?? '',
      Email: data.Email ?? '',
      UserPassword: data.UserPassword,
      DeviceId: '',
      DeviceDescription: '',
      PushId: '',
      VoipId: '',
      Application: 'test',
      FirstName: data.FirstName,
      Surname: data.Surname
    };

    setLoading(true);

    let res;
    try {
      res = await dispatch(addUserThunk(request)).unwrap();
    } catch (error) {
      dispatch(
        showSnackbar({
          type: error.status === 409 ? 'info' : 'error',
          message:
            error.status === 409
              ? 'Указанный пользователь уже существует'
              : error.status === 500
              ? 'Ошибка создания пользователя'
              : error.ErrorMessage
        })
      );
      setLoading(false);
    }

    if (!res) return false;

    await Promise.all([
      ...data.Roles.map((roleId) =>
        dispatch(addUserRoleThunk({ roleId, userId: res.Id })).unwrap()
      )
    ]);

    await dispatch(searchUsersThunk()).unwrap();
    setLoading(false);
    dispatch(
      showSnackbar({
        type: 'success',
        message: `Пользователь ${res.Login} успешно добавлен`
      })
    );
    onClose();
  };

  const getTextFieldErrorProps = (name) => {
    const message = form.formState.errors[name]?.message;

    if (!message) return {};

    return {
      error: true,
      helperText: message
    };
  };

  React.useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  React.useEffect(() => {
    if (loginProp === 'Phone') {
      form.setValue('Phone', form.getValues('Email') ?? '');
      form.setValue('Email', null);
    } else {
      form.setValue('Email', form.getValues('Phone') ?? '');
      form.setValue('Phone', null);
    }
  }, [loginProp, form]);

  return (
    <BaseDialog
      loading={loading}
      title="Добавление нового пользователя"
      actions={
        <>
          <Button onClick={onClose}>Отмена</Button>
          <Button onClick={form.handleSubmit(onSubmit)}>Создать</Button>
        </>
      }
      onClose={onClose}
    >
      <Stack spacing={2}>
        <Stack direction="row" spacing={1}>
          <Controller
            control={form.control}
            name={loginProp}
            render={({ field }) => (
              <TextField
                size="small"
                label="Логин"
                fullWidth
                {...field}
                value={field?.value ?? ''}
                {...getTextFieldErrorProps(loginProp)}
              />
            )}
          />
          <ToggleButtonGroup
            exclusive
            size="small"
            value={loginProp}
            onChange={(_, value) => {
              if (!value) return;
              setLoginProp(value);
            }}
            sx={{ flexShrink: 0, alignSelf: 'baseline' }}
          >
            <ToggleButton value="Phone">Телефон</ToggleButton>
            <ToggleButton value="Email">E-mail</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Controller
          control={form.control}
          name="UserPassword"
          render={({ field }) => (
            <TextField
              size="small"
              label="Пароль"
              {...field}
              {...getTextFieldErrorProps('UserPassword')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="ConfirmPassword"
          render={({ field }) => (
            <TextField
              size="small"
              label="Повторить пароль"
              {...field}
              {...getTextFieldErrorProps('ConfirmPassword')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="FirstName"
          render={({ field }) => (
            <TextField
              size="small"
              label="Имя"
              {...field}
              {...getTextFieldErrorProps('FirstName')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="Surname"
          render={({ field }) => (
            <TextField
              size="small"
              label="Фамилия"
              {...field}
              {...getTextFieldErrorProps('Surname')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="Roles"
          render={({ field }) => (
            <Autocomplete
              multiple
              disableCloseOnSelect
              size="small"
              value={roles.filter((role) => field.value.includes(role.Id))}
              options={roles}
              getOptionLabel={(option) => `#${option.Id} ${option.Name}`}
              onChange={(_, value) =>
                field.onChange(value.map((item) => item.Id))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getTextFieldErrorProps('Roles')}
                  label="Роль"
                />
              )}
            />
          )}
        />
      </Stack>
    </BaseDialog>
  );
};

const schema = yup.object().shape(
  {
    Phone: yup
      .string()
      .nullable()
      .when('Email', {
        is: null,
        then: yup.string().required(formErrorMessages.required)
      }),
    Email: yup
      .string()
      .email(formErrorMessages.email)
      .nullable()
      .when('Phone', {
        is: null,
        then: yup
          .string()
          .email(formErrorMessages.email)
          .required(formErrorMessages.required)
      }),
    UserPassword: yup.string().required(formErrorMessages.required),
    ConfirmPassword: yup
      .string()
      .required(formErrorMessages.required)
      .oneOf(
        [yup.ref('UserPassword'), null],
        formErrorMessages.confirmPassword
      ),
    FirstName: yup.string().required(formErrorMessages.required),
    Surname: yup.string().required(formErrorMessages.required),
    Roles: yup.array().min(1, formErrorMessages.arrayMin(1))
  },
  ['Phone', 'Email']
);
