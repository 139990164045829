import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Autocomplete,
  TextField,
  Button
} from '@mui/material';

import { useDebounce } from 'shared/lib/debounce';
import { search } from 'entities/user';

const isMySchool = process.env.REACT_APP_PROJECT_TYPE === 'MY_SCHOOL';

let abortController;

export const SearchUserDialog = ({
  open,
  onSuccess,
  excludeUserIds,
  onClose
}) => {
  const [list, setList] = React.useState([]);

  const debounced = useDebounce(async (event) => {
    if (event.type === 'change') {
      if (abortController) {
        abortController.abort();
      }

      abortController = new AbortController();

      try {
        const result = await search(event.target.value, abortController.signal);
        setList(
          result.filter(
            (item) => item.Name && !excludeUserIds.includes(item.Id)
          )
        );
      } finally {
        abortController = null;
      }
    }
  }, 500);

  const handleChange = (_, value) => {
    if (value?.Id) {
      onSuccess(value);
      onClose();
    }
  };

  const setInputRef = React.useCallback((node) => {
    if (node) {
      node.focus();
    }
  }, []);

  React.useEffect(() => {
    if (!open) {
      setList([]);
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Поиск пользователя</DialogTitle>
      <DialogContent sx={{ pt: 1.5 }}>
        <Box sx={{ mt: 1.5 }}>
          <Autocomplete
            freeSolo
            options={list}
            getOptionLabel={(option) => (!option?.Id ? '' : option.Name)}
            onInputChange={debounced}
            onChange={handleChange}
            renderOption={(props, option) => (
              <li {...props} key={option.Id}>
                {props.key}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={setInputRef}
                label={isMySchool ? 'Email пользователя' : 'Имя пользователя'}
              />
            )}
            filterOptions={(x) => x}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
};
