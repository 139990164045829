import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { showSnackbar } from 'features/Snackbar';
import {
  rootDirectoriesConfig,
  documentSortFieldTypes,
  documentSortOrderTypes
} from './config';
import { getUserInfo } from 'entities/user';
import { userTaskStatuses, checkUserTask } from 'entities/userTask';
import { API_URL } from 'shared/lib/request';
import * as api from './api';

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const fetchDirectoryFn = async (paramsObj) => {
  const params = new URLSearchParams(
    Object.fromEntries(
      Object.entries(paramsObj).filter((values) => {
        return ![null, undefined].includes(values[1]);
      })
    )
  ).toString();

  let result = [];
  try {
    result = await api.getItem(params);
  } catch (e) {
    //cannot obtain rooms
    return null;
  }
  if (!result[0].Children) {
    result[0].Children = [];
  }

  if (!result[0].Documents) {
    result[0].Documents = [];
  }

  let owners = result[0].Documents.map((x) => x.OwnerId);
  if (result[0].Children)
    owners = owners
      .concat(result[0].Children.map((x) => x.AuthorId))
      .filter((x) => x);
  let users = await getUserInfo([...new Set(owners)]);
  if (!users) users = [];
  //potentially problematic place if too many files with different owners
  result[0].Documents.forEach((x) => {
    const user = users.find((user) => user.Id === x.OwnerId);
    x.UserName = user ? `${user.FirstName ?? ''} ${user.Surname ?? ''}` : '-'; //no user info for public link
    x.Email = user ? user.Email : '';
    x.UserAvatar = user ? user.Avatar : null;
    x.UserInitials = !user
      ? ''
      : `${user.Surname ? user.Surname[0] : '-'}${
          user.FirstName ? user.FirstName[0] : '-'
        }`;
  });
  if (result[0].Children)
    result[0].Children.forEach((x) => {
      const user = users.find((user) => user.Id === x.AuthorId);

      x.UserName = user ? `${user.FirstName ?? ''} ${user.Surname ?? ''}` : '-';
      x.Email = user ? user.Email : '';
      x.UserAvatar = user ? user.Avatar : null;
      x.UserInitials = !user
        ? ''
        : `${user.Surname ? user.Surname[0] : '-'}${
            user.FirstName ? user.FirstName[0] : '-'
          }`;
    });

  return result;
};

export const documentDirectorySearchThunk = createAsyncThunk(
  'documentDirectory/searchThunk',
  async (payload) => {
    const response = await api.search(payload);
    const ownerIds = response.Directory.Items.concat(
      response.Document.Items
    ).map((item) => item.OwnerId);

    const users = await getUserInfo([...new Set(ownerIds)]);

    for (const key in response) {
      response[key].Items.forEach((item) => {
        const user = users.find((user) => user.Id === item.OwnerId);

        item.UserName = user ? `${user.FirstName} ${user.Surname}` : '-'; //no user info for public link
        item.Email = user ? user.Email : '';

        if (key === 'Directory') {
          item.IsFolder = true;
        }
      });
    }

    return response;
  }
);

export const fetchRootDirectoriesThunk = createAsyncThunk(
  'documentDirectory/fetchRootDirectoriesThunk',
  async () => {
    try {
      const root = await api.getItem();
      const roomsData = root.find(
        (item) => item.Type === rootDirectoriesConfig.rooms.type
      );
      //roomsData = undefined;
      if (roomsData) {
        const rooms = await fetchDirectoryFn({
          id: roomsData.Id,
          sortField: documentSortFieldTypes.Id,
          sortOrder: documentSortOrderTypes.Desc
        });

        if (!rooms) return { root };
        return { root, rooms: rooms[0] };
      }

      return { root };
    } catch (error) {}
  }
);

export const fetchRoomsDirectoryThunk = createAsyncThunk(
  'documentDirectory/fetchRoomsDirectoryThunk',
  async (_, { getState }) => {
    const rooms = await fetchDirectoryFn({
      id: getState().documentDirectory.rootDirectories.find(
        (item) => item.name === 'rooms'
      ).id,
      sortField: documentSortFieldTypes.Id,
      sortOrder: documentSortOrderTypes.Desc
    });
    return rooms[0];
  }
);

// export const fetchDirectoryThunk = createAsyncThunk(
//   'documentDirectory/fetchDirectory',
//   api.getItem
// );

export const changeDirectoryRequestThunk = createAsyncThunk(
  'documentDirectory/changeDirectoryRequestThunk',
  (payload, { dispatch, getState }) => {
    const request = {
      ...getState().documentDirectory.directoryRequest,
      ...payload
    };

    dispatch(setDirectoryRequest(request));
    dispatch(fetchDirectoryThunk());
  }
);

export const fetchDirectoryThunk = createAsyncThunk(
  'documentDirectory/fetchDocumentDirectory',
  async (_, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await fetchDirectoryFn(
        getState().documentDirectory.directoryRequest
      );
      return fulfillWithValue(result);
    } catch (error) {
      if (error.ErrorCode === 406) {
        dispatch(
          showSnackbar({
            type: 'warning',
            message: 'Недостаточно прав'
          })
        );
        return rejectWithValue(error);
      }
      throw rejectWithValue(error);
    }
  }
);

export const deleteDirectoryThunk = createAsyncThunk(
  'documentDirectory/deleteDirectoryThunk',
  async (payload, { dispatch }) => {
    try {
      const { TaskId } = await api.deleteItem(payload);

      const loop = async () => {
        const response = await checkUserTask(TaskId);

        if (response.Status === userTaskStatuses.error) {
          dispatch(
            showSnackbar({
              type: 'error',
              message: 'Ошибка удаления'
            })
          );
          return;
        }

        if (response.Status === userTaskStatuses.done) {
          return;
        }

        await wait(1000);
        await loop();
      };

      await wait(1000);
      await loop();
    } catch (error) {
      dispatch(
        showSnackbar({
          type: 'error',
          message: 'Ошибка удаления'
        })
      );
    }
  }
);

export const restoreDirectoryThunk = createAsyncThunk(
  'documentDirectory/restoreDirectoryThunk',
  async (Ids, { dispatch, getState }) => {
    try {
      return await api.restoreItem({ Ids });
    } catch {
      const ToDirectoryId = getState().documentDirectory.rootDirectories.find(
        (x) => x.name === 'docs'
      ).id;

      dispatch(
        showSnackbar({
          type: 'error',
          message:
            'Невозможно восстановить в исходную папку. Восстановлено в "мои документы'
        })
      );

      return await api.restoreItem({ Ids, ToDirectoryId });
    }
  }
);

export const shareDirectoryThunk = createAsyncThunk(
  'documentDirectory/shareDirectoryThunk',
  api.share
);

export const unshareDirectoryThunk = createAsyncThunk(
  'documentDirectory/unshareDirectoryThunk',
  api.unshare
);

export const renameDirectoryThunk = createAsyncThunk(
  'documentDirectory/renameDirectoryThunk',
  async (params, { dispatch }) => {
    try {
      const data = await api.rename(params);
      return data;
    } catch (error) {
      if (error.ErrorCode === 409) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: `Папка с именем ${params.name} уже существует`
          })
        );
      }

      throw error;
    }
  }
);

export const moveDirectoryThunk = createAsyncThunk(
  'documentDirectory/moveDirectoryThunk',
  api.move
);

export const conflictDirectoryThunk = createAsyncThunk(
  'documentDirectory/conflictDirectoryThunk',
  api.conflict
);

export const copyDirectoryThunk = createAsyncThunk(
  'documentDirectory/copyDirectoryThunk',
  api.copy
);

export const addSubDirectoryThunk = createAsyncThunk(
  'documentDirectory/addSubDirectoryThunk',
  async (body, { dispatch }) => {
    try {
      const response = await api.addSubDirectory(body);

      dispatch(
        showSnackbar({
          type: 'success',
          message: `Папка ${body.Name} добавлена!`
        })
      );

      return response;
    } catch (error) {
      if (error.status === 409) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: `Папка ${body.Name} уже существует!`
          })
        );
      }
    }
  }
);

export const addRoomThunk = createAsyncThunk(
  'documentDirectory/addRoomThunk',
  async (body, { dispatch }) => {
    try {
      const response = await api.addSubDirectory(body);

      dispatch(
        showSnackbar({
          type: 'success',
          message: `Комната ${body.Name} добавлена!`
        })
      );

      return response;
    } catch (error) {
      if (error.status === 409) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: `Комната ${body.Name} уже существует!`
          })
        );
      }
    }
  }
);

export const updateDirectoryThunk = createAsyncThunk(
  'documentDirectory/updateDirectoryThunk',
  async (body, { dispatch }) => {
    if (!body.Name) {
      throw new Error('Name field is required');
    }

    try {
      const response = await api.updateDirectory(body);
      dispatch(
        showSnackbar({
          type: 'success',
          message: `${body.Color ? 'Комната' : 'Папка'} ${body.Name} сохранена!`
        })
      );

      return response;
    } catch (error) {
      if (error.status === 409) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: `Папка ${body.Name} уже существует!`
          })
        );

        throw error;
      }
    }
  }
);

export const archiveThunk = createAsyncThunk(
  'documentDirectory/archiveThunk',
  async (body, { dispatch }) => {
    try {
      const { TaskId } = await api.archive(body);
      let maxTryCount = 50;

      const loop = async () => {
        const response = await checkUserTask(TaskId);

        if (response.Status === userTaskStatuses.error) {
          dispatch(
            showSnackbar({
              type: 'error',
              message: `Невозможно выгрузить указанную папку"`
            })
          );

          return;
        }

        if (response.Status === userTaskStatuses.done) {
          window.open(`${API_URL}/v1/UserTask/Download?Id=${TaskId}`);
          //await downloadUserTask(TaskId);
        } else {
          maxTryCount--;
          if (maxTryCount === 0) {
            dispatch(
              showSnackbar({
                type: 'error',
                message: `Превышен лимит ожидания выполнения задачи по получению папки"`
              })
            );
            return;
          }

          await wait(1000);
          await loop();
        }
      };

      await loop();
    } catch (error) {}
  }
);

export const archiveDocsThunk = createAsyncThunk(
  'documentDirectory/archiveDocsThunk',
  async (body, { dispatch }) => {
    try {
      const { TaskId } = await api.archiveDocs(body);
      let maxTryCount = 50;

      const loop = async () => {
        const response = await checkUserTask(TaskId);

        if (response.Status === userTaskStatuses.error) {
          dispatch(
            showSnackbar({
              type: 'error',
              message: 'Невозможно выгрузить файлы'
            })
          );

          return;
        }

        if (response.Status === userTaskStatuses.done) {
          window.open(`${API_URL}/v1/UserTask/Download?Id=${TaskId}`);
          //await downloadUserTask(TaskId);
        } else {
          maxTryCount--;
          if (maxTryCount === 0) {
            dispatch(
              showSnackbar({
                type: 'error',
                message: `Превышен лимит ожидания выполнения задачи по получению папки"`
              })
            );
            return;
          }

          await wait(1000);
          await loop();
        }
      };

      await loop();
    } catch (error) {}
  }
);

const initialState = {
  directoryRequest: {
    id: null,
    offset: null,
    size: null,
    uid: null,
    sortField: documentSortFieldTypes.Id,
    sortOrder: documentSortOrderTypes.Desc
  },
  rootDirectories: [],
  roomsDirectory: null,
  rootDirectoriesStatus: 'initial',
  directory: null,
  directoryStatus: 'initial'
};

export const documentDirectorySlice = createSlice({
  name: 'documentDirectory',
  initialState,
  reducers: {
    setDirectoryRequest: (state, action) => {
      state.directoryRequest = action.payload;
    },
    updateDocument: (state, action) => {
      state.directory = {
        ...state.directory,
        Documents: state.directory.Documents.map((doc) =>
          doc.Id !== action.payload.Id ? doc : { ...doc, ...action.payload }
        )
      };
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchDirectoryThunk.pending, (state) => {
        state.directoryStatus = 'pending';
      })
      .addCase(fetchDirectoryThunk.fulfilled, (state, action) => {
        state.directoryStatus = 'success';
        state.directory = action.payload[0];
      })
      .addCase(fetchDirectoryThunk.rejected, (state) => {
        state.directoryStatus = 'error';
      })
      .addCase(fetchRootDirectoriesThunk.pending, (state) => {
        state.rootDirectoriesStatus = 'pending';
      })
      .addCase(fetchRootDirectoriesThunk.fulfilled, (state, action) => {
        state.rootDirectoriesStatus = 'success';

        if (!action.payload) return null;

        const { root, rooms } = action.payload;

        state.roomsDirectory = rooms;

        state.rootDirectories = Object.values(rootDirectoriesConfig)
          .filter((obj) => root.find((item) => item.Type === obj.type))
          .map((obj) => {
            const dataItem = root.find((item) => item.Type === obj.type);

            return {
              ...obj,
              id: dataItem.Id,
              title: dataItem.Name,
              type: dataItem.Type,
              order: dataItem.Order
            };
          })
          .sort((a, b) => a.order - b.order);
      })
      .addCase(fetchRootDirectoriesThunk.rejected, (state) => {
        state.rootDirectoriesStatus = 'error';
      })
      .addCase(fetchRoomsDirectoryThunk.fulfilled, (state, action) => {
        state.roomsDirectory = action.payload;
      })
});

export const documentDirectoryReducer = documentDirectorySlice.reducer;

export const { setDirectoryRequest, updateDocument } =
  documentDirectorySlice.actions;

export const selectDirectoryRequest = (state) =>
  state.documentDirectory.directoryRequest;

export const selectRootDirectories = (state) =>
  state.documentDirectory.rootDirectories;

export const selectRootDirectoriesStatus = (state) =>
  state.documentDirectory.rootDirectoriesStatus;

export const selectRootDirectory = (state) => {
  let current = state.documentDirectory.directory;

  while (current?.Parent) {
    current = current.Parent;
  }

  return (
    state.documentDirectory.rootDirectories.find(
      (dir) => dir.id === current?.Id
    ) ?? current
  );
};

export const selectDirectory = (state) => state.documentDirectory.directory;
export const selectDirectoryStatus = (state) =>
  state.documentDirectory.directoryStatus;

export const selectRoomsDirectory = (state) =>
  state.documentDirectory.roomsDirectory;
