import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  value: {
    type: 'info',
    message: ''
  }
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    updateValue: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    }
  }
});

export const snackbarReducer = snackbarSlice.reducer;

export const { setOpen, updateValue } = snackbarSlice.actions;

export const selectOpen = (state) => state.snackbar.open;
export const selectValue = (state) => state.snackbar.value;

export const showSnackbar = (payload) => (dispatch) => {
  dispatch(setOpen(true));
  dispatch(updateValue(payload));
};
