import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { styled, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { useBreadcrumbs } from 'shared/lib/breadcrumbs';

export const Breadcrumbs = () => {
  const crumbs = useBreadcrumbs();
  return (
    <MuiBreadcrumbs separator={<ChevronRight />}>
      {crumbs.map((crumb) => (
        <StyledNavLink key={crumb.pathname} to={crumb.pathname}>
          {crumb.route.title ?? crumb.params.folderId}
        </StyledNavLink>
      ))}
    </MuiBreadcrumbs>
  );
};

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  'color': theme.palette.text.primary,
  'fontSize': '1.125rem',
  '&:not(:hover)': {
    textDecoration: 'none'
  }
}));
