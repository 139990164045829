import { Snackbar as MuiSnackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { selectOpen, selectValue, setOpen } from './slice';

export const Snackbar = () => {
  const dispatch = useDispatch();

  const open = useSelector(selectOpen);
  const value = useSelector(selectValue);

  return (
    <MuiSnackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      onClose={() => dispatch(setOpen(false))}
    >
      <Alert variant="filled" severity={value.type}>
        {value.message}
      </Alert>
    </MuiSnackbar>
  );
};
