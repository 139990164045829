import * as React from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector /* useSelector */ } from 'react-redux';
import AppVersion from 'preval';
// import CryptoPbkdf2 from 'crypto-js/pbkdf2';
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
  Stack
} from '@mui/material';

import { Login } from 'shared/components/Login';
import {
  selectSettings,
  setViewer,
  setSettings,
  getSettings,
  loginThunk
} from 'entities/auth';
import { selectRootDirectoriesStatus } from 'entities/documentDirectory';
import { Sidebar, Header } from 'widgets';
import { Snackbar, showSnackbar } from 'features/Snackbar';
import { routes } from 'routing';
import { muiTheme } from './muiTheme';
import { getAuthInfo } from 'entities/user';
import { useIsPublicRoute, RoutesContainer } from 'routing';
import { GOS_USLUGI_AUTH } from 'shared/lib/request';
import { isMySchool, isR7 } from 'shared/constants';

const isDev = process.env.NODE_ENV === 'development';
const SYSTEM_NAME = 'CdDisk';
let editorParams;

window.appVersion = AppVersion;
window.getEditorParams = () => editorParams;

//crutch to open office files during local deveopment
window.open = (function (open) {
  return function (url, name, features) {
    if (isDev && url.includes('doc.html'))
      url += '&callBackUrl=' + process.env.REACT_APP_API_CALLBACK_URL;
    return open.call(window, url, name, features);
  };
})(window.open);

export const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [userData, setUserData] = React.useState({});

  const rootDirectioriesStatus = useSelector(selectRootDirectoriesStatus);

  const isPublicRoute = useIsPublicRoute();

  const settings = useSelector(selectSettings);

  const initEditor = (email) => {
    try {
      const params = {
        displayName:
          /*typeof(Teamlab) !== "undefined" ? Teamlab.profile.displayName :*/ 'CDDISK',
        domain: new RegExp('^http(s)?://[^/]+').exec(window.location)[0],
        email /*typeof(Teamlab) !== "undefined" ? Teamlab.profile.email :*/,
        provider: 'r7disk'
      };
      editorParams = JSON.stringify(params);

      window.AscDesktopEditor?.execCommand('portal:login', editorParams);
    } catch (_) {
      /* do nothing */
    }
  };

  //allow ppl w/o name and surname to live
  const fixUserData = (data) => {
    if (!data.FirstName) data.FirstName = '-';
    if (!data.Surname) data.Surname = '-';

    setUserData(data);
  };

  React.useEffect(() => {
    if (rootDirectioriesStatus !== 'success') return;

    const isInDirectoryPath = matchRoutes(routes, location);

    if (!isInDirectoryPath && userData.Id) {
      navigate('/docs');
    }
  }, [location, navigate, rootDirectioriesStatus, userData]);

  React.useLayoutEffect(() => {
    if (isDev && window.location.href.includes('localhost')) {
      window.location.href = process.env.REACT_APP_ORIGIN_URL;
      return;
    }

    const getUserData = async () => {
      try {
        //get server settings
        const settings = await getSettings();
        dispatch(setSettings(settings));
        if (settings.UserId > 0) {
          const data = await getAuthInfo();
          initEditor(data.Email);
          fixUserData(data);
          dispatch(setViewer(data));
        } else if (isR7) {
          if (location.search.includes('login=true')) setUserData({ Id: 0 });
          else {
            if (window.location.href.toLowerCase().includes('link/'))
              throw new Error('public link');

            const moduleUrls = settings.ModuleSettings.ModuleUrls;
            window.location.href =
              moduleUrls.find((x) => x.Name === 'Admin').Url +
              '/login?returnUrl=' +
              escape(window.location.href);
          }
        } else throw new Error('not authorized');
      } catch (error) {
        if (
          isMySchool &&
          !isDev &&
          !window.location.href.toLowerCase().includes('link/') &&
          !location.search.includes('login=true')
        )
          window.location.href = GOS_USLUGI_AUTH;
        else setUserData({ Id: 0 });
      }
    };
    getUserData();
    // eslint-disable-next-line
  }, [dispatch]);

  const loginHandle = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    /*
    const key = CryptoPbkdf2(formData.get("Password"), userSettings.SecuritySetting.PasswordHashSalt, {
      keySize: userSettings.SecuritySetting.PasswordHashSize /32,
      iterations: userSettings.SecuritySetting.PasswordHashIterations
    });

    const hash = key.words.slice(0, userSettings.SecuritySetting.PasswordHashSize / 32);

    const toHexString = (byteArray) => Array.from(byteArray, (byte) => ('0' + (byte & 0xFF).toString(16)).slice(-2)).join('');

    formData.set("PasswordHash", toHexString(hash));
    formData.set("Password", null);
    */

    // var bits = sjcl.misc.pbkdf2(password, salt, iterations);
    // bits = bits.slice(0, size / 32);
    // var hash = sjcl.codec.hex.fromBits(bits);

    const requestValues = Object.fromEntries(formData.entries());
    requestValues.SystemName = SYSTEM_NAME;

    if (
      requestValues.Login.trim() === '' ||
      requestValues.Password.trim() === ''
    ) {
      return;
    }

    try {
      await dispatch(loginThunk(requestValues)).unwrap();
      const params = window.location.search;
      if (params.includes('client_id')) window.location = '/auth.html' + params;
      else window.location.reload();
    } catch (error) {
      if (error.status === 401 || error.status === 406) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: 'Неверное имя пользователя или пароль'
          })
        );
      } else if (error.status === 403) {
        dispatch(
          showSnackbar({
            type: 'warning',
            message: 'Учетная запись заблокирована'
          })
        );
      } else if (error.status === 429) {
        dispatch(
          showSnackbar({
            type: 'warning',
            message: 'Исчерпан лимит попыток'
          })
        );
      }
      //setSnackbar({...snackbar, opened: true, text: "Неверное имя пользователя или пароль", type: "error"});
    }
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        {!isPublicRoute && (!userData || userData.Id === 0) && (
          <Login loginHandle={loginHandle} settings={settings} />
        )}
        {!!(isPublicRoute || (userData && userData.Id)) && (
          <Stack direction="row" sx={{ height: '100dvh' }}>
            <Sidebar initRoot="/MyDocuments" />
            <Stack sx={{ flexGrow: 1 }}>
              <Header />
              <RoutesContainer />
            </Stack>
          </Stack>
        )}
        <Snackbar />
      </StyledEngineProvider>
    </ThemeProvider>
  );
};
