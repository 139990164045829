import * as React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const PasswordField = React.forwardRef((props, ref) => {
  const [type, toggleType] = React.useReducer(
    (state) => (state === 'password' ? 'text' : 'password'),
    'password'
  );

  return (
    <TextField
      ref={ref}
      size="small"
      type={type}
      autoComplete="new-password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={toggleType}
              onMouseDown={(event) => event.preventDefault()}
            >
              {type === 'password' ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
});
