import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  useTheme,
  useMediaQuery,
  styled,
  Box,
  Stack,
  Popover,
  MenuList,
  MenuItem,
  Typography,
  Divider,
  IconButton
} from '@mui/material';
import { Apps } from '@mui/icons-material';

import { selectViewer } from 'entities/auth';
import { Search } from 'features/search';
import { DirectorySearch } from 'features/directorySearch';
import { AppsMenu } from '../../features/AppsMenu';
import { UserMenu } from '../../features/UserMenu';
import { isR7, isMySchool, widgets } from 'shared/constants';
import analyticsSrc from 'shared/svg/icon-analytics.svg';
import educationSrc from 'shared/svg/icon-education.svg';
import assistantSrc from 'shared/svg/icon-assistant.svg';
import psychologicalSrc from 'shared/svg/icon-psychological.svg';
import cloudSrc from 'shared/svg/icon-cloud.svg';
import libIconSrc from 'shared/svg/icon-lib.svg';
import testsIconSrc from 'shared/svg/icon-tests.svg';
import fiveIconSrc from 'shared/svg/icon-five.svg';
import cultureIconSrc from 'shared/svg/icon-culture.svg';
import sphefumIconSrc from 'shared/svg/icon-spherum.svg';
import reshIconSrc from 'shared/svg/icon-resh.svg';
import ticketOfTheFutureImgSrc from 'shared/svg/icon-ticket-of-the-future.svg';

export const Header = () => {
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const viewer = useSelector(selectViewer);

  const [servicesMenuAnchorEl, setServicesMenuAnchorEl] = React.useState(null);

  return (
    <>
      <Root className={isMobile ? 'mobile' : ''}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: '100%', pr: isMobile ? 1 : 3 }}
        >
          {!!viewer && !location.pathname.includes('/version') && (
            <>
              {location.pathname.includes('/admin') ? (
                <Search sx={{ width: '60%' }} />
              ) : (
                <DirectorySearch />
              )}

              <Stack
                direction="row"
                spacing={isMobile ? 1 : 2}
                sx={{ flexShrink: 0 }}
              >
                {isR7 && <AppsMenu />}
                {isMySchool && (
                  <>
                    <IconButton
                      onClick={(event) =>
                        setServicesMenuAnchorEl(event.currentTarget)
                      }
                    >
                      <Apps />
                    </IconButton>
                    <Popover
                      open={!!servicesMenuAnchorEl}
                      anchorEl={servicesMenuAnchorEl}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      onClose={() => setServicesMenuAnchorEl(null)}
                    >
                      <Box sx={{ width: 960 }}>
                        <Typography
                          sx={{
                            fontWeight: 'medium',
                            p: 2
                          }}
                        >
                          Сервисы
                        </Typography>
                        <Divider />
                        <Stack direction="row">
                          <SchoolMenu>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://data.edu.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#275ff1', color: '#fff' }}
                                >
                                  <img src={analyticsSrc} alt="Аналитика" />
                                </SchoolMenuItemIconBox>
                                Аналитика
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://lib.myschool.edu.ru/market">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#ffcc57' }}
                                >
                                  <img src={libIconSrc} alt="Библиотека" />
                                </SchoolMenuItemIconBox>
                                Библиотека
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://lesson.edu.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#ffcc57' }}
                                >
                                  <img
                                    src={libIconSrc}
                                    alt="Библиотека Минпросвещения"
                                  />
                                </SchoolMenuItemIconBox>
                                Библиотека Минпросвещения
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://bvbinfo.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#1f968c' }}
                                >
                                  <img
                                    src={ticketOfTheFutureImgSrc}
                                    alt="Билет в будущее"
                                  />
                                </SchoolMenuItemIconBox>
                                Билет в будущее
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://extra.edu.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#f87545' }}
                                >
                                  <img
                                    src={educationSrc}
                                    alt="Дополнительное образование"
                                  />
                                </SchoolMenuItemIconBox>
                                Дополнительное образование
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://teacher.edu.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#f86459' }}
                                >
                                  <img
                                    src={assistantSrc}
                                    alt="Помощник учителя"
                                  />
                                </SchoolMenuItemIconBox>
                                Помощник учителя
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                          </SchoolMenu>
                          <SchoolMenu>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://parent.edu.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#f86459' }}
                                >
                                  <img
                                    src={assistantSrc}
                                    alt="Помощник родителя"
                                  />
                                </SchoolMenuItemIconBox>
                                Помощник родителя
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://student.edu.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#f86459' }}
                                >
                                  <img
                                    src={assistantSrc}
                                    alt="Помощник ученика"
                                  />
                                </SchoolMenuItemIconBox>
                                Помощник ученика
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://pshl.edu.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#38bbfc' }}
                                >
                                  <img
                                    src={psychologicalSrc}
                                    alt="Психологическая служба"
                                  />
                                </SchoolMenuItemIconBox>
                                Психологическая служба
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://resh.edu.ru">
                                <SchoolMenuItemIconBox>
                                  <img
                                    src={reshIconSrc}
                                    alt="Российская электронная школа"
                                  />
                                </SchoolMenuItemIconBox>
                                Российская электронная школа
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://sferum.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#071354' }}
                                >
                                  <img src={sphefumIconSrc} alt="Сферум" />
                                </SchoolMenuItemIconBox>
                                Сферум
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://tests.myschool.edu.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#48b462' }}
                                >
                                  <img src={testsIconSrc} alt="Тесты" />
                                </SchoolMenuItemIconBox>
                                Тесты
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                          </SchoolMenu>
                          <SchoolMenu>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://docs.myschool.edu.ru">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#e3b672' }}
                                >
                                  <img src={cloudSrc} alt="Файлы" />
                                </SchoolMenuItemIconBox>
                                Файлы
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://myschool.edu.ru/404">
                                <SchoolMenuItemIconBox
                                  sx={{ bgcolor: '#43c6cc' }}
                                >
                                  <img
                                    src={fiveIconSrc}
                                    alt="Электронный журнал/дневник"
                                  />
                                </SchoolMenuItemIconBox>
                                Электронный журнал/дневник
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                            <SchoolMenuItem>
                              <SchoolMenuItemLink href="https://www.culture.ru/literature">
                                <SchoolMenuItemIconBox>
                                  <img
                                    style={{ heght: 32, width: 32 }}
                                    src={cultureIconSrc}
                                    alt="Художественная литература"
                                  />
                                </SchoolMenuItemIconBox>
                                Художественная литература
                              </SchoolMenuItemLink>
                            </SchoolMenuItem>
                          </SchoolMenu>
                        </Stack>
                      </Box>
                    </Popover>
                  </>
                )}
                {!isMobile && <UserMenu />}
              </Stack>
            </>
          )}
        </Stack>
      </Root>
    </>
  );
};

const Root = styled('header')(({ theme }) => ({
  'borderBottom': `1px solid ${theme.palette.divider}`,
  'height': `${widgets.headerHeight}px`,
  'flexShrink': 0,
  '&.mobile': {
    paddingLeft: 48
  }
}));

const SchoolMenu = styled((props) => (
  <MenuList
    sx={{
      px: 2
    }}
    {...props}
  />
))({});

const SchoolMenuItem = styled((props) => <MenuItem sx={{ p: 0 }} {...props} />)(
  {}
);

const SchoolMenuItemLink = styled((props) => (
  <Box
    component="a"
    sx={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      fontSize: 14,
      p: 1.3,
      color: 'inherit',
      textDecoration: 'none'
    }}
    {...props}
  />
))({});

const SchoolMenuItemIconBox = styled((props) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{ borderRadius: '20%', height: 32, width: 32, mr: 1.8 }}
    {...props}
  />
))({});
