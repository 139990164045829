import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as api from './api';

export const addUserRoleThunk = createAsyncThunk(
  'user/addUserRoleThunk',
  api.addRole
);

export const removeUserRoleThunk = createAsyncThunk(
  'user/removeUserRoleThunk',
  api.removeRole
);

export const searchUsersThunk = createAsyncThunk(
  'user/searchUsersThunk',
  async (params) => api.search(params)
);

export const changeUserStatusThunk = createAsyncThunk(
  'user/changeUserStatusThunk',
  api.changeStatus
);

const initialState = {
  searchList: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(searchUsersThunk.fulfilled, (state, action) => {
      state.searchList = action.payload;
    })
});

export const userReducer = userSlice.reducer;

export const selectSearchUsers = (state) => state.user.searchList;
