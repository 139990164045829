import { request } from 'shared/lib/request';

export const generateLink = ({
  id,
  type = 'Document',
  accessType,
  lifeTime,
  uid,
  password,
  expirationDate
}) =>
  request(
    `v1/Link/Generate?${new URLSearchParams(
      Object.entries({
        id,
        type,
        accessType,
        lifeTime,
        uid,
        password,
        expirationDate: !expirationDate ? undefined : expirationDate.toJSON()
      }).filter((item) => item[1])
    ).toString()}`
  );

export const generate = (
  documentId,
  accessType,
  lifeTime,
  uid,
  type = 'Document'
) =>
  request(
    `v1/Link/Generate?id=${documentId}&type=${type}&lifeTime=${lifeTime}&accessType=${accessType}` +
      (uid ? `&uid=${uid}` : '')
  );

export const editLink = (body) =>
  request('v1/Link/Edit', { method: 'PUT', body });

export const info = (uid, password) =>
  request(`v1/Link/Info?uid=${uid}${password ? '&password=' + password : ''}`);

export const deleteItem = (uid) =>
  request(`v1/Link?uid=${uid}`, { method: 'DELETE' }, 'raw');

export const getLinksByIds = ({ EntityType, Ids }) =>
  request('v1/Link/GetByIds', { method: 'POST', body: { EntityType, Ids } });

export const getAllLinks = () => request('v1/Link/All');

export const deleteLink = (uid) =>
  request(`v1/Link?Uid=${uid}`, { method: 'DELETE' }, 'raw');
