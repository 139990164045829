import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper, Stack, Divider, Typography } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';

import {
  selectMediaCards,
  selectMinimizedMediaCards,
  toggleVisibleMediaCarrd,
  removeMediaCarrd,
  setMediaCardPosition,
  setFocusMediaCard
} from './slice';
import { MediaCard } from './MediaCard';

export const MediaCards = () => {
  const dispatch = useDispatch();

  const cards = useSelector(selectMediaCards);
  const minimizedCards = useSelector(selectMinimizedMediaCards);

  const handleToggleVisible = React.useCallback(
    (card) => dispatch(toggleVisibleMediaCarrd(card.uid)),
    [dispatch]
  );

  const handleRemove = React.useCallback(
    (card) => dispatch(removeMediaCarrd(card.uid)),
    [dispatch]
  );

  const handleCardMouseDown = React.useCallback(
    (card) => dispatch(setFocusMediaCard(card)),
    [dispatch]
  );

  const handleDragEnd = React.useCallback(
    (payload) => dispatch(setMediaCardPosition(payload)),
    [dispatch]
  );

  return (
    <>
      {cards.map((card) => (
        <MediaCard
          key={card.uid}
          card={card}
          onFocus={handleCardMouseDown}
          onMinimize={handleToggleVisible}
          onRemove={handleRemove}
          onDragEnd={handleDragEnd}
        />
      ))}
      {!!minimizedCards.length && (
        <Paper
          elevation={8}
          sx={{
            position: 'fixed',
            zIndex: 999999,
            bottom: 20,
            left: 20,
            borderRadius: 1,
            py: 0.5,
            px: 1
          }}
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <PlayArrow sx={{ fontSize: 28 }} />

            {minimizedCards.map((card) => (
              <React.Fragment key={card.uid}>
                <Divider flexItem orientation="vertical" />
                <Box
                  sx={{ 'cursor': 'pointer', '&:hover': { color: '#ff6a6a' } }}
                  onClick={() => handleToggleVisible(card)}
                >
                  <Typography noWrap sx={{ maxWidth: 100 }} variant="body2">
                    {card.title}
                  </Typography>
                </Box>
              </React.Fragment>
            ))}
          </Stack>
        </Paper>
      )}
    </>
  );
};
