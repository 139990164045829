import { store } from 'app/store';
import { showSnackbar } from 'features/Snackbar';

export const API_URL = window.location.origin + '/api';
export const GOS_USLUGI_AUTH = API_URL + '/v1/OAuth20/Code';

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const exit = async (statusCode) => {
  const isDev = process.env.NODE_ENV === 'development';
  const isMySchool = process.env.REACT_APP_PROJECT_TYPE === 'MY_SCHOOL';

  if (statusCode !== 401) {
    var data = await request('v2/auth/logout');
    if (data?.Response?.Data?.LogoutUrl) {
      window.location.href = data.Response.Data.LogoutUrl;
      return;
    }
  }

  if (isMySchool && !isDev)
    window.location = 'https://myschool.edu.ru'; // GOS_USLUGI_AUTH;
  else window.location = '/';
};

export const request = async (path, options, responseType = 'json') => {
  const url = `${API_URL}/${path}`;
  const authToken = getCookie('AuthToken');
  const config = {
    headers: {
      ...(authToken && { Authorization: authToken }),
      ...(!(options?.body instanceof FormData) &&
        responseType !== 'raw' && {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json'
        })
      //'Cache-Control': 'no-cache',
    },
    method: options?.method ?? 'GET',
    credentials: 'same-origin',
    signal: options?.signal,
    body: !options?.body
      ? undefined
      : options.body instanceof FormData
      ? options.body
      : JSON.stringify(options.body)
  };

  try {
    const response = await fetch(url, config);
    if (!response.ok) {
      const errorText = await response.text();
      const error = {
        status: response.status,
        url: response.url
      };

      if (errorText) {
        try {
          const errorData = JSON.parse(errorText);
          Object.assign(error, errorData);
        } catch (e) {
          error.description = errorText;
        }
      }

      throw error;
    }

    switch (responseType) {
      case 'raw':
        for (var pair of response.headers.entries()) {
          console.log(pair[0] + ': ' + pair[1]);
        }
        return response.arrayBuffer();
      case 'text':
        return response.text();
      default:
        return await response.json();
    }
  } catch (error) {
    // const isPublicRoute = !!matchRoutes(routes, window.location.pathname)
    //   ?.length;

    // if (error.status === 401 && isPublicRoute) return;

    if (error.status === 502) {
      store.dispatch(
        showSnackbar({
          type: 'error',
          message: 'Технические работы на сервере'
        })
      );
    } else if (
      error.status === 401 &&
      !path.includes('Login') &&
      !path.includes('/user')
    ) {
      // !path.includes('Link/Generate') &&
      // !path.includes('Link/Info') &&
      // !path.includes('Documents/Download') &&
      // !path.includes('/Share') &&
      // !path.includes('/user')
      alert(
        'Отсутствует токен авторизации. Необходимо выполнить вход в систему заново.'
      );
      exit(401);
    } else if (error.status === 403) {
      alert('Пользователь заблокирован');
      exit();
    } else {
      if (error.ErrorCode)
        console.log(`Code: ${error.ErrorCode}, Message: ${error.ErrorMessage}`);
      throw error;
    }
  }
};
