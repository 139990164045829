import * as React from 'react';
import { Stack, CircularProgress } from '@mui/material';

import { widgets, zIndex } from 'shared/constants';

export const RouteElementWrapper = ({ element }) => (
  <React.Suspense fallback={<Spinner />}>{element}</React.Suspense>
);

const Spinner = () => (
  <Stack
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: `calc(100% - ${widgets.headerHeight}px)`,
      width: `calc(100% - ${widgets.sidebarWidth}px)`,
      position: 'fixed',
      top: `${widgets.headerHeight}px`,
      left: `${widgets.sidebarWidth}px`,
      zIndex: zIndex.loading
    }}
  >
    <CircularProgress disableShrink size={40} sx={{ m: 'auto' }} />
  </Stack>
);
