import * as React from 'react';
import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { LoadingBox } from 'shared/components';

export const BaseDialog = ({
  loading,
  title,
  actions,
  children,
  onClose,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      {...props}
      onClose={onClose}
      PaperProps={{
        sx: !isMobile ? {} : { margin: 0, width: 'calc(100% - 16px)' }
      }}
    >
      <LoadingBox loading={loading}>
        <DialogTitle sx={{ padding: isMobile ? 1.5 : '' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {title}
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: isMobile ? 1.5 : '' }}>
          {children}
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </LoadingBox>
    </Dialog>
  );
};
