import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useTheme,
  useMediaQuery,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Stack,
  Typography,
  Divider
} from '@mui/material';
import {
  AdminPanelSettingsOutlined,
  StorageOutlined,
  AccountBalanceOutlined,
  NotificationsActiveOutlined,
  AccountCircleOutlined,
  LogoutOutlined,
  HomeOutlined
} from '@mui/icons-material';

import { isR7, isMySchool, isRosKazna } from 'shared/constants';
import { exit } from 'shared/lib/request';
import { roleTypes } from 'entities/role/config';
import { selectViewer, selectSettings } from 'entities/auth';
import { UserProfileDialog } from 'dialogs';

export const UserMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const viewer = useSelector(selectViewer);
  const settings = useSelector(selectSettings);

  const hasAdminAccess =
    settings.ModuleSettings.ModulesAccess.includes('Admin');
  const adminUrl = settings.ModuleSettings.ModuleUrls.find(
    (x) => x.Name === 'Admin'
  )?.Url;

  const [anchorEl, setAnchorEl] = React.useState();
  const [userProfileDialogOpen, setUserProfileDialogOpen] =
    React.useState(false);

  const handleClose = () => setAnchorEl(null);
  if (!viewer) return null;

  return (
    <>
      <Avatar
        sx={{ cursor: 'pointer', height: 42, width: 42 }}
        src={viewer.Avatar ? `/api/v2/profile/getAvatar?id=${viewer.Id}` : null}
        title={`${viewer.FirstName} ${viewer.Surname}`}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleClose}
      >
        <Stack sx={{ minWidth: 300 }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ px: 2.5, pt: 0.5 }}
          >
            <Avatar
              sx={{ cursor: 'pointer', height: 50, width: 50 }}
              src={
                viewer.Avatar
                  ? `/api/v2/profile/getAvatar?id=${viewer.Id}`
                  : null
              }
              title={`${viewer.FirstName} ${viewer.Surname}`}
            />
            <Stack>
              <Typography
                sx={{ fontSize: 18 }}
              >{`${viewer.FirstName} ${viewer.Surname}`}</Typography>
              <Typography sx={{ fontSize: 14, color: 'text.secondary' }}>
                {viewer.Email}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ my: 1.5 }} />
          {!isR7 && (
            <>
              {!!viewer.Roles.find((role) => role.Type === roleTypes.Admin) &&
                !location.pathname.includes('/admin') && (
                  <MenuItem
                    onClick={() => navigate('/admin/users')}
                    sx={{ px: 2.5, minHeight: isMobile ? 40 : '' }}
                  >
                    <ListItemIcon>
                      <AdminPanelSettingsOutlined sx={{ fontSize: 23 }} />
                    </ListItemIcon>
                    <Typography>Админпанель</Typography>
                  </MenuItem>
                )}
              {location.pathname.includes('/admin') && (
                <MenuItem
                  onClick={() => navigate('/docs')}
                  sx={{ px: 2.5, minHeight: isMobile ? 40 : '' }}
                >
                  <ListItemIcon>
                    <StorageOutlined sx={{ fontSize: 24 }} />
                  </ListItemIcon>
                  <Typography>Диск</Typography>
                </MenuItem>
              )}
            </>
          )}
          {isMySchool && (
            <>
              <MenuItem
                component="a"
                target="_blank"
                href="https://myschool.edu.ru/profile"
                sx={{ px: 2.5, minHeight: isMobile ? 40 : '' }}
              >
                <ListItemIcon>
                  <AccountBalanceOutlined sx={{ fontSize: 24 }} />
                </ListItemIcon>
                <Typography>Личный кабинет</Typography>
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                href="https://myschool.edu.ru/notifications"
                sx={{ px: 2.5, minHeight: isMobile ? 40 : '' }}
              >
                <ListItemIcon>
                  <NotificationsActiveOutlined sx={{ fontSize: 24 }} />
                </ListItemIcon>
                <Typography>Уведомления</Typography>
              </MenuItem>
            </>
          )}
          {isR7 && (
            <MenuItem
              component="a"
              href={`${adminUrl}/dashboard`}
              sx={{ px: 2.5, minHeight: isMobile ? 40 : '' }}
            >
              <ListItemIcon>
                <HomeOutlined sx={{ fontSize: 24 }} />
              </ListItemIcon>
              <Typography>Главная</Typography>
            </MenuItem>
          )}
          {isRosKazna && (
            <MenuItem
              onClick={() => setUserProfileDialogOpen(true)}
              sx={{ px: 2.5, minHeight: isMobile ? 40 : '' }}
            >
              <ListItemIcon>
                <AccountCircleOutlined sx={{ fontSize: 24 }} />
              </ListItemIcon>
              <Typography>Профиль</Typography>
            </MenuItem>
          )}
          {isR7 && hasAdminAccess && (
            <MenuItem
              component="a"
              href={`${adminUrl}/profile`}
              sx={{ px: 2.5, minHeight: isMobile ? 40 : '' }}
            >
              <ListItemIcon>
                <AccountCircleOutlined sx={{ fontSize: 24 }} />
              </ListItemIcon>
              <Typography>Профиль</Typography>
            </MenuItem>
          )}
          <MenuItem
            onClick={exit}
            sx={{ px: 2.5, minHeight: isMobile ? 40 : '' }}
          >
            <ListItemIcon>
              <LogoutOutlined sx={{ fontSize: 22 }} />
            </ListItemIcon>
            Выйти
          </MenuItem>
        </Stack>
      </Menu>
      {userProfileDialogOpen && (
        <UserProfileDialog
          user={viewer}
          onClose={() => setUserProfileDialogOpen(false)}
        />
      )}
    </>
  );
};
